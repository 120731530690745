import {styled} from '@mui/material/styles';

export const Page = styled('div', {name: 'Page'})({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  boxSizing: 'border-box',
});

export const Main = styled('main', {name: 'Main'})(({theme}) => ({
  width: 'calc(100% - 300px)',
  padding: theme.spacing(3.75),
  background: theme.palette.lightGray,
  boxSizing: 'border-box',

  '& h2': {
    marginBottom: theme.spacing(6.25),
  }
}));
