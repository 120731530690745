import React, {
  useEffect, useMemo,
  useState,
} from 'react';
import { useFormikContext } from 'formik';
import * as L from 'lodash';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { Chip } from '@mui/material';
import MuiAutocomplete from '@mui/material/Autocomplete';

import * as Styled from './auto-complete.styles';

export const AutoComplete = ({name, options, label, editable, ...props}) => {
  const { setFieldValue, getFieldMeta } = useFormikContext();
  const meta = getFieldMeta(name);
  const value = meta.value;

  const [inputValue, setInputValue] = useState();
  const [selectedOptions, setSelectedOptions] = useState(
    value ?? []
  );

  const isNewValue = useMemo(() => {
    return !options.find(
      (option) => option.value === inputValue?.value
    )
  }, [options, inputValue?.value]);

  const preparedOptions =
    isNewValue && inputValue ? [inputValue, ...options] : options;

  useEffect(() => {
    setFieldValue(
      name,
      selectedOptions.map((option) => ({
        title: option.title,
        value: option.value,
      }))
    );
  }, [selectedOptions]);

  const handleInputChange = (event, value) => {
    if (!editable) {
      return;
    }

    if (value) {
      setInputValue({
        isNew: true,
        title: value,
        value: value.trim(),
      });
    } else {
      setInputValue(options[0]);
    }
  };

  const handleAutocompleteChange = (
    event,
    value,
    reason,
    details
) => {
    if (
      selectedOptions.find((option) => option.value === details?.option.value)
    ) {
      return setSelectedOptions(
        selectedOptions.filter(
          (option) => option.value !== details?.option.value
        )
      );
    }

    setSelectedOptions(value);
  };

  return (
    <Styled.AutoCompleteWrapper>
      {label && <Typography sx={{ mb: 0.5 }}>{label}</Typography>}
      <MuiAutocomplete
        multiple
        options={preparedOptions}
        getOptionLabel={(option) => {
          return option?.title
        }}
        disableCloseOnSelect
        size="small"
        value={value}
        onInputChange={handleInputChange}
        onChange={handleAutocompleteChange}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={L.isEmpty(selectedOptions) ? 'Select' : ''}
            onKeyDown={(event) => {
              if (event.key === 'Enter' && inputValue) {
                event.preventDefault();
                setSelectedOptions((options) => [...options, inputValue]);
              }
            }}
          />
        )}
        renderTags={(tagValue, getTagProps) => {
          return tagValue.map((option, index) => (
            <Chip
              {...getTagProps({ index })}
              label={option.render || option.title}
              size="small"
              key={option.value}
              sx={{
                '& .MuiChip-label': {
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                },
              }}
            />
          ));
        }}
        renderOption={(props, option, state) => {
          const preparedProps = {
            ...props,
            'aria-selected': !!selectedOptions.find(
              (selectedOption) => selectedOption.value === option.value
            ),
            sx: {
              backgroundColor: option.isNew ? 'light2' : '',
            },
          };

          if (option.render) {
            return <Typography {...preparedProps}>{option.render}</Typography>;
          }

          return <Typography {...preparedProps}>{option.title}</Typography>;
        }}
        {...props}
      />
    </Styled.AutoCompleteWrapper>
  );
};