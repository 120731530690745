import {useQuery} from "@tanstack/react-query";
import {getAllUsers} from '../api-service/get-users';
import {filterUsers} from "../../helpers/filter-users";

export const useGetAllUsers = ({ filters, ...options }) => {
  return useQuery({
    queryKey: ['users'],
    queryFn: getAllUsers,
    select: ({data}) => filterUsers(filters, data),
    ...options
  })
}
