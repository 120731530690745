import {useQuery} from "@tanstack/react-query";
import {getAllProviders} from "../api-service/get-all-providers";


export const useGetAllProviders = () => {
  return useQuery({
    queryKey: ['providers'],
    queryFn: getAllProviders,
    select: ({data}) => data.filter(provider => provider.hidden !== 1).map(provider => ({
      ...provider,
      balance: +(-Number(provider.balance)).toFixed(2),
    })),
  })
}