import {styled} from "@mui/material";
import {DataGrid} from "@mui/x-data-grid";

export const UsersTable = styled(DataGrid, {name: 'UsersTable'})(({theme}) => ({
  '& .MuiDataGrid-columnHeaders': {
    background: theme.palette.white,
  },

  '& .MuiDataGrid-cell': {
    fontSize: theme.spacing(1.75),
    background: theme.palette.tableBg
  }
}))
