import PropTypes from 'prop-types';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CloseIcon from '@mui/icons-material/Close';

import * as Styled from './users-table.styled';

const columns = [
  {
    field: 'username',
    headerName: 'Логин',
    flex: 1,
    sortable: false
  },
  {
    field: 'type',
    headerName: 'Тип',
    flex: 1,
    sortable: false
  },
  {
    field: 'minimumBalanceThreshold',
    headerName: 'Порог уведомления баланса',
    flex: 1,
    sortable: false,
    renderCell: ({row: user}) => `$${user.minimumBalanceThreshold}`
  },
  {
    field: 'balance',
    headerName: 'Баланс',
    flex: 1,
    sortable: false,
    renderCell: ({row: user}) => Number(user.balance).toFixed(2)
  },
  {
    field: 'chats',
    headerName: 'Telegram Bot',
    flex: 1,
    sortable: false,
    filter: false,
    align: 'center',
    headerAlign: 'center',

    renderCell: ({row: user}) => {
      if (user.chats.length > 0) {
        return <CheckCircleOutlineIcon sx={(theme) => ({color: theme.palette.green})}/>
      }

      return <CloseIcon sx={(theme) => ({color: theme.palette.orange})}/>
    }
  },
  {
    field: 'telegramToken',
    headerName: 'Telegram token',
    flex: 1,
    sortable: false
  },
];


export const UsersTable = ({users}) => {
  return(
    <Styled.UsersTable
      autoHeight
      columns={columns}
      disableColumnFilter
      disableRowSelectionOnClick
      getRowId={(row) => {
        return row.morId;
      }}
      rows={users}
    />
  )
}

UsersTable.propTypes = {
  users: PropTypes.arrayOf(
    PropTypes.shape({
      balance: PropTypes.number.isRequired,
      chats: PropTypes.array.isRequired,
      isAuthorizedThroughTelegram: PropTypes.bool,
      username: PropTypes.string.isRequired,
      minimumBalanceThreshold: PropTypes.number.isRequired,
      morId: PropTypes.number.isRequired,
      permissions: PropTypes.array.isRequired,
      requests: PropTypes.object,
      telegramToken: PropTypes.string.isRequired,
      transactions: PropTypes.array.isRequired,
      type: PropTypes.string.isRequired
    }).isRequired
  ).isRequired,
}
