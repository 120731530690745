import PropTypes from 'prop-types';
import {providerColumns} from "./providers-table.utils";
import * as Styled from "./providers-table.styled";

export const ProvidersTable = ({providers}) => {
  return (
    <Styled.ProvidersTableDataGrid
      autoHeight
      columnVisibilityModel={{
        id: false
      }}
      columns={providerColumns}
      rows={providers}
      initialState={{
        sorting: {
          sortModel: [{ field: 'balance', sort: 'desc' }],
        },
      }}
      getRowId={row => row.morId}
    />
  )
}

ProvidersTable.propTypes = {
  providers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      tech: PropTypes.string.isRequired,
      port: PropTypes.string.isRequired,
      server_ip: PropTypes.string.isRequired,
      balance: PropTypes.string.isRequired,
    })
  ).isRequired
}
