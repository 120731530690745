import {generatePath, useNavigate} from "react-router-dom";
import PaidIcon from '@mui/icons-material/Paid';
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from '@mui/icons-material/Edit';

import {useModal} from "../../../../hooks/use-modal";
import {MODAL_IDS} from "../../../../components/modals/constants";
import {ROUTES} from "../../../../system/router/routes";

const PaymentIcon = ({row: provider}) => {
  const {openModal} = useModal();

  return (
    <PaidIcon
      onClick={() => openModal({
        id: MODAL_IDS.REFILL_PROVIDER,
        data: {
          provider: provider,
        }
      })}
    />
  )
}

const EditCell = ({row: provider}) => {
  const navigate = useNavigate();

  return (
    <EditIcon onClick={() => navigate(generatePath(ROUTES.PROVIDER, {
      id: provider.morId
    }))}/>
  )
}

export const providerColumns = [
  {
    field: 'id',
    flex: 1,
    sortable: false,
  },
  {
    field: 'name',
    flex: 1,
    sortable: false,
    headerName: 'Name',
  },
  {
    field: 'tech',
    flex: 1,
    sortable: false,
    headerName: 'Technology',
  },
  {
    field: 'port',
    flex: 1,
    sortable: false,
    headerName: 'Port',
  },
  {
    field: 'server_ip',
    flex: 1,
    sortable: false,
    headerName: 'Hostname / IP',
  },
  {
    field: 'balance',
    flex: 1,
    sortable: true,
    headerName: 'Balance',
  },
  {
    field: 'chats',
    headerName: 'Telegram Bot',
    flex: 1,
    sortable: false,
    filter: false,
    align: 'center',
    headerAlign: 'center',

    renderCell: ({row: provider}) => {
      if (provider.chats.length > 0) {
        return <CheckCircleOutlineIcon sx={(theme) => ({color: theme.palette.green})}/>
      }

      return <CloseIcon sx={(theme) => ({color: theme.palette.orange})}/>
    }
  },
  {
    field: 'telegramToken',
    headerName: 'Telegram token',
    flex: 1,
    sortable: false
  },
  {
    field: 'payment',
    flex: 1,
    sortable: false,
    headerName: '',
    renderCell: PaymentIcon
  },
  {
    field: 'edit',
    flex: 1,
    sortable: false,
    headerName: '',
    renderCell: EditCell
  }
]