import {Box} from "@mui/material";

export const FormSection = ({sx, children}) => {
  return (
    <Box
      sx={{
        padding: '16px',
        backgroundColor: '#fff',
        borderRadius: '8px',
        mb: 2,
      }}
    >
      {children}
    </Box>
  )
}