import {useParams} from "react-router-dom";
import {useGetProvider} from "../../../modules/providers/api/api-hooks/use-get-provider";
import {Form, Formik} from "formik";
import {Button, Typography} from "@mui/material";
import {useUpdateProvider} from "../../../modules/providers/api/api-hooks/use-update-provider";
import {
  ProviderSettingsFormFields
} from "../../../modules/providers/components/forms/provider-form-fields/provider-settings-form-fields";
import {Loader} from "../../../components/loader/loader";
import {useNotification} from "../../../hooks/use-notification/useNotification";
import {usePermissionAccess} from "../../../hooks/use-permission-access";
import {PERMISSIONS} from "../../../../../app/permissions/permissions";

export const ProviderEditPage = () => {
  const {id} = useParams();
  const { showNotification } = useNotification();
  const { hasPermissions } = usePermissionAccess();

  const {data: provider, isLoading, isLoadingError} = useGetProvider(id);
  const { mutate: updateProviderApi } = useUpdateProvider({
    providerId: id,
    onSuccess: ({data}) => {
      showNotification({
        type: 'success',
        description: data.message,
      })
    },
    onError: ({response: {data}}) => {
      showNotification({
        type: 'error',
        description: data.message,
      })
    },
  });

  return (
    <>
      {isLoading && <Loader />}
      {provider && (
        <Formik
          initialValues={{
            ...provider,
            usernames: provider.usernames?.map(item => ({title: item, value: item})) || []
          }}
          onSubmit={(data, formikHelpers) => {
            if (!hasPermissions(PERMISSIONS.PROVIDER.EDIT)) {
              return showNotification({
                type: 'error',
                description: 'You cannot edit provider',
              })
            }

            updateProviderApi({
              ...data,
              usernames: data.usernames.map(item => item.value)
            });

            formikHelpers.setSubmitting(false);
          }}
        >
          <Form>
            <ProviderSettingsFormFields provider={provider}/>

            {hasPermissions(PERMISSIONS.PROVIDER.EDIT) && (
              <Button type="submit" variant="contained">Save</Button>
            )}
          </Form>
        </Formik>
      )}
      {isLoadingError && <Typography>Provider loading error...</Typography>}
    </>
  )
}