import {useUser} from "./use-user";
import {hasPermissions as hasPermissionsHelper} from "../../../app/helpers/has-permissions";
import {PERMISSIONS} from "../../../app/permissions/permissions";
import {useCallback} from "react";

export const usePermissionAccess = () => {
  const user = useUser();

  const isSuperAdmin = user?.permissions.includes(PERMISSIONS.SUPER_ADMIN);

  const hasPermissions = useCallback((permissions) => {
    if (!user) return true;
    if (isSuperAdmin) return true;

    if (Array.isArray(permissions)) {
      return permissions.some((permission) => {
        return user?.permissions?.includes(permission)
          || user.permissions?.includes(PERMISSIONS.SUPER_ADMIN);
      });
    }

    // || currentUser.userType === 'admin'

    return user?.permissions?.includes(permissions);
  }, [user, isSuperAdmin])

  return {
    isSuperAdmin,
    hasPermissions,
  }
}