import {useDispatch, useSelector} from "react-redux";
import {selectCallsSearchPeriod} from "../../store/call-search.selectors";
import {setSearchPeriod} from "../../store/call-search.slice";
import {PeriodPicker} from "../../../../components/shared/period-picker";

export const CallsSearchPeriod = () => {
  const dispatch = useDispatch();
  const period = useSelector(selectCallsSearchPeriod);

  const handlePeriodChange = (period) => {
    dispatch(setSearchPeriod(period));
  }

  return <PeriodPicker selectedPeriod={period} onChange={handlePeriodChange}/>;
}