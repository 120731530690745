import {Box, Typography} from "@mui/material";

export const QuickStat = ({stat}) => {
  return(
    <Box>
      <Typography sx={(theme) => ({mb: 2, fontSize: theme.spacing(2.125)})} variant="h6">
        Вызовы: {stat.calls}
      </Typography>
      <Typography sx={(theme) => ({mb: 2, fontSize: theme.spacing(2.125)})} variant="h6">
        Длительность: {stat.duration}
      </Typography>
    </Box>
  )
}
