import {createSlice} from "@reduxjs/toolkit";

import {loginUser, logoutUser} from "../auth/auth.actions";

const initialState = {
  user: null,
  searchPhone: null,
  userData: null,
}

export const userSlice = createSlice({
  name: 'user-slice',
  initialState,
  reducers: {
    setSearchPhone: (state, action) => {
      state.searchPhone = action.payload;
    },
    setUserData: (state, action) => {
      state.userData = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(loginUser.fulfilled, (state, action) => {
      state.user = {
        ...state.user,
        ...action.payload,
      };
    });
    builder.addCase(logoutUser.fulfilled, (state) => {
      state.user = null;
    })
  }
})

export const {logout, setUserData} = userSlice.actions;
export default userSlice.reducer;