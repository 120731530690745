import {Box, Button, Typography} from '@mui/material';
import {Loader} from '../../../components/loader/loader'
import {useGetAllProviders} from "../../../modules/providers/api/api-hooks/use-get-all-providers";
import {ProvidersTable} from "../../../modules/providers/components/providers-table/providers-table";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import {useSyncProviders} from "../../../modules/providers/api/api-hooks/use-sync-providers";
import {useEffect} from "react";
import {httpService} from "../../../system/api/http-service";

export const ProvidersListPage = () => {
  const { refetch: getProvidersApi, data: providers, isFetching: isProvidersFetching} = useGetAllProviders();
  const { refetch: syncProvidersApi, isFetching: isSyncFetching } = useSyncProviders();

  useEffect(() => {
    // httpService.get('wallet/transactions/manual')
    // httpService.get('wallet/transactions?address=TDdfDdyXNdRZtfs6cDkqcsRbo3e2cYkfdb')
  }, []);

  return (
    <>
      <Typography sx={{mb: 4}} variant="h5">Провайдеры</Typography>

      <Box sx={{mb: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
        <Box sx={{width: (theme) => theme.spacing(18.75)}}>
          <Button
            onClick={syncProvidersApi}
            variant="contained"
            size="small"
            fullWidth
          >
            {isSyncFetching ? <Loader size={18} color="white"/> : 'Синхронизировать'}
          </Button>
        </Box>

        <Button
          variant="outlined"
          size="small"
          onClick={getProvidersApi}
          sx={{color: 'dark4'}}
        >
          <AutorenewIcon/>
        </Button>
      </Box>

      {isProvidersFetching ? (
        <Loader />
      ) : (
        // eslint-disable-next-line no-extra-boolean-cast
        !!providers ? (
          <ProvidersTable providers={providers}/>
        ) : (
          <>Error. No data available</>
        )
      )}
    </>
  )
}