import {styled} from '@mui/material/styles';

export const AudioPlayerWrapper = styled('div')`
  position: fixed;
  bottom: 0;
  right: 0;
  width: 350px;
  background: #fff;
    //background: ${({theme}) => theme.palette.primary.main};
  padding: 15px;
  border-radius: 5px 0 0 0;
  border-top: 2px solid;
  border-left: 2px solid;
  border-right: 2px solid;
  border-color: ${({theme}) => theme.palette.primary.main};

  & .rhap_container {
    box-shadow: none;
    padding: 10px 0;
    border-top: 2px solid #000;
  }
`