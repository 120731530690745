import { styled } from '@mui/material/styles';
import AccordionSummary from '@mui/material/AccordionSummary';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';

export const SidebarMenu = styled(Box)(({ theme }) => ({
  background: 'transparent',

  '& .MuiPaper-root': {
    background: 'transparent',
    boxShadow: 'none',
  },
}));

export const SidebarSelectedMenuItem = styled(AccordionSummary)(
  ({ theme }) => ({
    minHeight: theme.spacing(4.75),
    borderRadius: theme.spacing(0.75),

    '&.Mui-expanded': {
      minHeight: theme.spacing(4.75),
    },

    '& .MuiListItemIcon-root': {
      display: 'flex',
      alignItems: 'center',
    },

    '& .MuiAccordionSummary-content, & .MuiAccordionSummary-content.Mui-expanded':
      {
        margin: 0,
      },
  })
);

export const SidebarMenuItem = styled(ListItem)(
  ({ theme }) => ({
    color: theme.palette.dark0,
    padding: 0,
    position: 'relative',

    '& .MuiListItemIcon-root': {
      padding: `0`,
      // padding: `0 ${theme.spacing(1)}`,
      marginRight: theme.spacing(2),
      minWidth: 'auto',
    },

    '&.active': {
      // background: theme.palette.primary.light,
      // color: theme.palette.primary.main,
      // borderRadius: theme.spacing(0.75),

      '&:before': {
        transform: `translateX(-50px)`,
      },

      '& .MuiListItemIcon-root path': {
        fill: `rgb(255, 134, 113)`,
      },
    },

    '& .MuiAccordionSummary-root.MuiButtonBase-root': {
      // padding: 0
      minHeight: '40px',
      maxHeight: '40px',
    },

    '& .MuiAccordionDetails-root': {
      padding: '0px 16px',

      '& .MuiListItemText-root': {
        marginLeft: theme.spacing(1)
      }
    },

    '& .MuiPaper-root': {
      width: '100%',
      boxShadow: 'none',
    },

    '&:before': {
      content: '\'\'',
      display: 'block',
      position: 'absolute',
      width: '100px',
      height: '100%',
      background: 'rgb(255 117 124 / 8%)',
      borderTopRightRadius: theme.spacing(0.625),
      borderBottomRightRadius: theme.spacing(0.625),
      transform: `translateX(-120px)`,
      transition: '0.2s transform',
    },

    '&:hover': {
      // ...theme.typography.body14semibold,
      '&:before': {
        transform: `translateX(-50px)`,
      },

      '& > .MuiListItemIcon-root path': {
        fill: `rgb(255, 134, 113)`
      },
    },

    '& .MuiTypography-root': {
      fontSize: '16px',
    },
  })
);

export const SidebarMenuItemButton = styled(ListItemButton)(({ theme }) => ({
  borderRadius: theme.spacing(0.75),
  height: theme.spacing(5),
  display: 'flex',
  alignItems: 'center',

  '&:hover': {
    backgroundColor: 'inherit',

    '& .MuiListItemIcon-root path': {
      fill: `rgb(255, 134, 113)`
    },
  },

  '& .MuiTypography-root': {
    fontSize: theme.spacing(2),
  },
}));

export const SidebarHeaderItemWrapper = styled(Box)(({ theme }) => ({
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  marginTop: theme.spacing(1),
}));

export const SidebarHeaderItem = styled(Typography)(({ theme }) => ({
  textTransform: 'uppercase',
  color: theme.palette.dark3,
  ...theme.typography.body12semibold,
}));
