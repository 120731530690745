import {createAsyncThunk} from "@reduxjs/toolkit";

import {RocketApi} from "../../api/rocket-api";
import {showNotification} from "../notifications/notifications.slice";

export const loginUser = createAsyncThunk(
  'auth-slice/loginUser',
  async ({username, password}, thunkAPI) => {
    const response = await RocketApi.loginUser({username, password});

    const allowedTypesToLogin = ['admin', 'accountant'];

    if (!response.userId || !allowedTypesToLogin.includes(response.userType)) {
      thunkAPI.dispatch(showNotification({
        type: 'error',
        title: 'Login failed',
        description: 'Something went wrong',
      }))

      return thunkAPI.rejectWithValue({});
    }

    return response;
  }
)

export const refreshToken = createAsyncThunk(
  'auth-slice/refreshToken',
  async (refreshToken, thunkAPI) => {
    const response = await RocketApi.refreshToken(refreshToken);

    if (!response.userId) {
      thunkAPI.dispatch(showNotification({
        type: 'error',
        title: 'Login failed',
        description: 'Something went wrong',
      }))
    }
    return response;
  }
)

export const logoutUser = createAsyncThunk(
  'auth-slice/logoutUser',
  async () => {
    const response = await RocketApi.logoutUser();

    return response;
  }
)
