import {TextField} from "formik-mui";
import {Field} from "formik";
import {Box, FormControlLabel, Switch} from "@mui/material";

export const BasicCheckboxField = ({label, ...props}) => {
  if (label) {
    return (
      <FormControlLabel
        control={(
          <Field
            component={Switch}
            {...props}
          >
            {({ field }) => (
              <Switch {...field} checked={field.value} {...props} />
            )}
          </Field>
        )}
        label={(
          <Box sx={{display: 'flex'}}>
            {label}
          </Box>
        )}
        sx={(theme) => ({
          alignItems: 'center',
          height: '100%',

          '& .MuiFormControlLabel-label': {
            marginBottom: theme.spacing(.5),
          }
          // display: 'flex',
          // flexDirection: 'column',
        })}
        labelPlacement="end"
      />
    )
  }

  return (
    <Field
      component={Switch}
      {...props}
    >
      {({ field }) => (
        <Switch {...field} checked={field.value} {...props} />
      )}
    </Field>
  )
}