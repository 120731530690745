import {useDispatch} from "react-redux";
import {showNotification} from "../store/notifications/notifications.slice";

export const useCopy = () => {
  const dispatch = useDispatch();

  return (text) => {
    navigator.clipboard.writeText(text);

    dispatch(showNotification({
      type: 'success',
      title: 'Текст скопирован'
    }))
  }
}