import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const AutoCompleteWrapper = styled(Box)(({ theme }) => ({
  '& .MuiAutocomplete-root': {
    minHeight: theme.spacing(4),
  },

  '& .MuiInputBase-root': {
    minHeight: theme.spacing(5),
    padding: 0,
    height: 'auto',
  },

  '& .MuiButtonBase-root': {
    padding: theme.spacing(1),
    bozSizing: 'border-box',
  },

  '& .MuiAutocomplete-endAdornment .MuiButtonBase-root': {
    padding: theme.spacing(0.375),
  },

  '& .MuiButtonBase-root.MuiChip-root': {
    padding: `${theme.spacing(0.25)} ${theme.spacing(0.5)}`,
    maxHeight: theme.spacing(3),
    borderRadius: theme.spacing(0.25),
  },

  '& .MuiFormControl-root .MuiInputBase-root.MuiInputBase-formControl': {
    padding: `${theme.spacing(0.25)} ${theme.spacing(0.5)}`,
  },
}));