import {BaseHttpService} from "./base-http-service";

export class HttpService extends BaseHttpService {
  constructor(props) {
    super(props);
  }

  get(endpoint, params) {
    return this.axiosInstance.get(endpoint, params)
  }

  post(endpoint, data) {
    return this.axiosInstance.post(endpoint, data)
  }

  put(endpoint, data) {
    return this.axiosInstance.put(endpoint, data)
  }
}

export const httpService = new HttpService();