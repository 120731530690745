import {Box, Button, Typography} from "@mui/material";
import {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {PeriodPickerWrapper} from "./period-picker.styles";

export const PERIODS = {
  FIFTEEN_MIN: {
    value: '15-min',
    label: '15 мин',
  },
  THIRTY_MIN: {
    value: '30-min',
    label: '30 мин',
  },
  ONE_HOUR: {
    value: 'one-hour',
    label: '1 час',
  },
  TWO_HOURS: {
    value: 'two-hours',
    label: '2 часа',
  },
  FOUR_HOURS: {
    value: 'four-hours',
    label: '4 часа',
  },
  DAY: {
    value: 'day',
    label: 'День'
  },
  WEEK: {
    value: 'week',
    label: 'Неделя'
  },
  // TWO_WEEKS: {
  //   value: 'two-weeks',
  //   label: 'Две недели'
  // },
  // MONTH: {
  //   value: 'month',
  //   label: 'Месяц'
  // },
  // ALL_TIME: {
  //   value: 'all-time',
  //   label: 'Все время'
  // },
}

export const PeriodPicker = ({selectedPeriod = PERIODS.ONE_HOUR, onChange}) => {
  const [period, setPeriod] = useState(selectedPeriod);

  useEffect(() => {
    onChange?.(period);
  }, [onChange, period]);

  useEffect(() => {
    setPeriod(selectedPeriod);
  }, [selectedPeriod]);

  const handleChange = (period) => {
    setPeriod(period);
  }

  return (
    <Box sx={{display: 'flex'}}>
      <PeriodPickerWrapper>
        <Typography sx={{mb: 1}}>Выберите период поиска:</Typography>

        <Box>
          {Object.values(PERIODS).map(period => {
            return (
              <Button
                key={period.value}
                onClick={() => handleChange(period)}
                variant={period.value === selectedPeriod.value ? 'contained' : 'outlined'}
                size="small"
                disableElevation
                sx={{
                  mr: 2,
                  color: 'dark3',
                }}
              >
                {period.label}
              </Button>
            )
          })}
        </Box>
      </PeriodPickerWrapper>
    </Box>
  )
}

PeriodPicker.propTypes = PropTypes.shape({
  selectedPeriod: PropTypes.oneOfType(
    PropTypes.oneOf(
      Object.values(PERIODS).map(p => p.value)
    )
  ),
})