import {useQuery} from "@tanstack/react-query";
import {getProviderPayments} from "../api-service/get-provider-payments";

export const useGetProvidersPayments = () => {
  return useQuery({
    queryKey: ['providers-payments'],
    queryFn: getProviderPayments,
    // select: ({data}) => data.filter(provider => provider.hidden !== 1).map(provider => ({
    //   ...provider,
    //   balance: +(-Number(provider.balance)).toFixed(2),
    // })),
  })
}