/* eslint-disable no-unused-vars */
export const HANGUP_CODES = {
  1: {
    en: 'Unallocated (unassigned) number',
    ru: 'Номер не найден или не существует'
  },
  2: {
    en: 'No route to specified transmit network',
    ru: 'Нет маршрута к указанной сети передачи'
  },
  3: {
    en: 'No route to destination',
    ru: 'Нет маршрута до набранного номера'
  },
  5: {
    en: 'Misdialled trunk prefix (national use)',
    ru: 'Неправильно набранный префикс транка (национальное использование)'
  },
  6: {
    en: 'Channel unacceptable',
    ru: 'Канал недоступен'
  },
  7: {
    en: 'Call awarded and being delivered in an established channel',
    ru: 'Звонок назначен и будет осуществлён по установленному каналу'
  },
  8: {
    en: 'Preemption',
    ru: 'Предоставление льгот'
  },
  14: {
    en: 'QoR: ported number',
    ru: 'QoR: портированный номер'
  },
  16: {
    en: 'Normal Clearing',
    ru: 'Нормальное завершение вызова'
  },
  17: {
    en: 'User busy',
    ru: 'Вызываемый абонент занят'
  },
  18: {
    en: 'No user responding',
    ru: 'Пользователь не отвечает'
  },
  19: {
    en: 'No answer from user (user alerted)',
    ru: 'Нет ответа от пользователя (пользователь уведомлен)'
  },
  20: {
    en: 'Subscriber absent',
    ru: 'Абонент отсутствует'
  },
  21: {
    en: 'Call Rejected',
    ru: 'Звонок отклонен'
  },
  22: {
    en: 'Number changed',
    ru: 'Номер изменен'
  },
  23: {
    en: 'Redirected to new destination',
    ru: 'Перенаправлен к новому месту назначения'
  },
  26: {
    en: 'Non-selected user clearing(ASTERISK-15057)',
    ru: 'Очистка невыбранного пользователя (ASTERISK-15057)'
  },
  27: {
    en: 'Destination out of order',
    ru: 'Место назначения не по порядку'
  },
  28: {
    en: 'Invalid number format',
    ru: 'Неверный формат номера'
  },
  29: {
    en: 'Facility rejected',
    ru: 'Объект отклонен'
  },
  30: {
    en: 'Response to STATUS ENQUIRY',
    ru: 'Ответ на СТАТУС ЗАПРОСА'
  },
  31: {
    en: 'Normal, unspecified',
    ru: 'Нормальный, неопределяемый'
  },
  34: {
    en: `No circuit/channel available`,
    ru: 'Отсутствует сеть/канал'
  },
  38: {
    en: 'Network out of order',
    ru: 'Сеть не работает'
  },
  41: {
    en: 'Temporary failure',
    ru: 'Временная поломка'
  },
  42: {
    en: 'Switching equipment congestion',
    ru: 'Перегрузка оборудования коммутации'
  },
  43: {
    en: 'Access information discarded',
    ru: 'Информация доступа удалена'
  },
  44: {
    en: 'Requested circuit/channel not available',
    ru: 'Запрошенный сеть/канал недоступен'
  },
  50: {
    en: 'Requested facility not subscribed',
    ru: 'Запрашиваемый объект не подписан'
  },
  52: {
    en: 'Outgoing call barred',
    ru: 'Запрет исходящего вызова'
  },
  54: {
    en: 'Incoming call barred',
    ru: 'Запрет входящего вызова'
  },
  57: {
    en: 'Bearer capability not authorized',
    ru: 'Возможности носителя не авторизованы'
  },
  58: {
    en: 'Bearer capability not presently available',
    ru: 'Возможности носителя в настоящее время недоступны'
  },
  65: {
    en: 'Bearer capability not implemented',
    ru: 'Возможности носителя не реализованы'
  },
  66: {
    en: 'Channel type not implemented',
    ru: 'Тип канала не реализован'
  },
  69: {
    en: 'Requested facility not implemented',
    ru: 'Запрошенная возможность не реализована'
  },
  81: {
    en: 'Invalid call reference value',
    ru: 'Неверная ссылка на вызов'
  },
  88: {
    en: 'Incompatible destination',
    ru: 'Несовместимый пункт назначения'
  },
  95: {
    en: 'Invalid message unspecified',
    ru: 'Недействительное сообщение не указано'
  },
  96: {
    en: 'Mandatory information element is missing',
    ru: 'Отсутствует обязательный информационный элемент'
  },
  97: {
    en: 'Message type non-existent or not implemented',
    ru: 'Тип сообщения не существует или не реализован'
  },
  98: {
    en: 'Message not compatible with call state or message type non-existent or not implemented',
    ru: 'Сообщение несовместимое с состоянием вызова или типом сообщения - не существует или не реализовано.'
  },
  99: {
    en: 'Information element nonexistent or not implemented',
    ru: 'Информационный элемент отсутствует или не реализован'
  },
  100: {
    en: 'Invalid information element contents',
    ru: 'Недопустимое содержание информационного элемента'
  },
  101: {
    en: 'Message not compatible with call state',
    ru: 'Сообщение не соответствует состоянию вызова'
  },
  102: {
    en: 'Recover on timer expiry',
    ru: 'Восстановление по истечении таймера'
  },
  '': {
    en: 'Mandatory IE length error',
    ru: 'Ошибка длины IE'
  },
  111: {
    en: 'Protocol error, unspecified',
    ru: 'неопределенная ошибка протокола'
  },
  127: {
    en: 'Interworking, unspecified',
    ru: 'Взаимодействие, не определено'
  },
  200: {
    en: `MOR can't determine who is calling`,
    ru: 'MOR не может определить кто звонит'
  },
  201: {
    en: 'User is blocked',
    ru: 'Пользователь заблокирован'
  },
  202: {
    en: 'Reseller is blocked',
    ru: 'Посредник заблокирован'
  },
  203: {
    en: 'No rates for User',
    ru: 'Нет тарифов для пользователя'
  },
  204: {
    en: 'No suitable providers found',
    ru: 'Не найдено подходящих провайдеров'
  },
  205: {
    en: 'MOR PRO not authorized to work on this computer',
    ru: 'Mor PRO не авторизован для работы на этом компьютере'
  },
  206: {
    en: 'server_id is not set in mor.conf file',
    ru: 'server_id не задан в файле mor.conf'
  },
  207: {
    en: 'Not clear who should receive call',
    ru: 'Не указано кто должен принимать звонки'
  },
  208: {
    en: 'MOR can not make more calls',
    ru: 'MOR не может совершать ешё большее кол-во вызовов'
  },
  209: {
    en: 'Test time exceeded',
    ru: 'Превышено время тестового периода'
  },
  210: {
    en: 'Balance more than 0, but not enough to make call 1s in length',
    ru: 'Положительный баланс, но этого недостаточно для звонка длиной в 1с'
  },
  211: {
    en: 'Low balance for user or Daily limit is reached',
    ru: 'Низкий баланс у пользователя или превышен дневной лимит'
  },
  212: {
    en: 'Balance too low for more simultaneous calls',
    ru: 'Низкий баланс для нескольких одновременных звонков'
  },
  213: {
    en: 'Low balance for DID owner',
    ru: 'У владельца DID низкий баланс'
  },
  214: {
    en: `DID owner's balance is too low to make more simultaneous calls`,
    ru: 'У владельца DID слишком низкий баланс, чтобы осуществлять одновременные звонки'
  },
  215: {
    en: 'Low balance for reseller',
    ru: 'Низкий баланс у реселлера'
  },
  216: {
    en: `Reseller's balance is too low to make more simultaneous calls`,
    ru: 'У реселлера слишком низкий баланс, чтобы осуществлять одновременные звонки'
  },
  217: {
    en: 'Callback not initiated because device not found by ANI',
    ru: 'Обратный вызов не инициирован, поскольку устройство не найдено по ANI'
  },
  218: {
    en: 'Reseller does not allow loss calls',
    ru: 'Реселлер не разрешает звонки с потерями'
  },
  219: {
    en: 'DID has reached call limit. No more calls allowed to this DID',
    ru: 'DID достиг лимита вызовов. На этот DID больше не разрешены вызовы'
  },
  220: {
    en: 'User call limit reached',
    ru: 'Достигнут лимит звонков для пользователя'
  },
  221: {
    en: 'Device call limit reached',
    ru: 'Достигнут лимит вызовов для устройства'
  },
  222: {
    en: 'Resellers call limit reached',
    ru: 'Лимит вызовов для реселлера достигнут'
  },
  223: {
    en: 'Providers call limit reached',
    ru: 'Лимит вызовов для провайдера достигнут'
  },
  224: {
    en: `Provider skipped because his rate is higher than user's rate`,
    ru: 'Провайдер пропущен, потому что его тариф выше тарифа пользователя'
  },
  225: {
    en: 'CallerID is banned',
    ru: 'CallerID забанен'
  },
  226: {
    en: 'DID is free',
    ru: 'DID свободен'
  },
  227: {
    en: 'Calling Card PIN is not entered',
    ru: 'Не введен PIN-код карты пользователя'
  },
  228: {
    en: 'Calling Card Destination is not entered',
    ru: 'Назначение телефонной карты не введено'
  },
  229: {
    en: 'DID is reserved',
    ru: 'DID зарезервирован'
  },
  230: {
    en: 'Connection returned error code 0 instead of real code',
    ru: 'Соединение вернуло код ошибки 0 вместо реального кода'
  },
  231: {
    en: 'Provider unreachable',
    ru: 'Провайдер недоступен'
  },
  232: {
    en: 'Callback DID not available',
    ru: 'Обратный вызов DID не доступен'
  },
  233: {
    en: 'Skype Addon Disabled',
    ru: 'Расширение Skype отключено'
  },
  234: {
    en: 'Internal Loop',
    ru: 'Внутренняя цикл'
  },
  235: {
    en: 'Providers rate higher by percent',
    ru: 'Тариф поставщика выше по проценту'
  },
  236: {
    en: 'Callback initiation too early',
    ru: 'Обратный вызов инициируется слишком рано'
  },
  237: {
    en: 'DID User is blocked',
    ru: 'Пользователь DID заблокирован'
  },
  238: {
    en: 'Provider used its daily call time limit',
    ru: 'Провайдер использовал весь дневной лимит времени звонка'
  },
  239: {
    en: 'Device used its daily call time limit',
    ru: 'Устройство использовало весь свой дневной лимит времени на разговоры'
  },
  240: {
    en: 'Provider belongs to the User which makes call (loop)',
    ru: 'Провайдер принадлежит Пользователю, что создаёт бесконечный цикл звонков'
  },
  241: {
    en: 'Local Caller canceled the call before call was answered',
    ru: 'Локальный абонент отменил вызов до того как он был отвечен'
  },
  242: {
    en: 'No Rates for Reseller',
    ru: 'Нет тарифов для реселлера'
  },
  243: {
    en: 'Provider skipped because of CPS limitation',
    ru: 'Провайдер пропущен из-за лимита CPS'
  },
  244: {
    en: 'Device call aborted because of CPS limitation',
    ru: 'Вызов устройства прерван из-за ограничения CPS'
  },
  245: {
    en: 'Provider skipped because of balance limitation',
    ru: 'Провайдер пропущен из-за ограничения баланса'
  },
  246: {
    en: 'Duplicate call hangup',
    ru: 'Дублирование вызова'
  },
  247: {
    en: 'Dead Provider skipped',
    ru: 'Недоступный провайдер пропущен'
  },
  248: {
    en: 'Call was terminated because FAS(False Answer Supervision) control detected an issue',
    ru: 'Вызов был прерван, поскольку система контроля FAS (надзор за ложными ответами) обнаружила проблему'
  },
  249: {
    en: `Partner's call limit reached`,
    ru: 'Лимит вызовов у партнера достигнут'
  },
  250: {
    en: 'Partner is blocked',
    ru: 'Партнёр заблокирован'
  },
  251: {
    en: 'Low balance for Partner',
    ru: 'Низкий баланс у партнёра'
  },
  252: {
    en: `Partner's balance is too low to make more simultaneous calls`,
    ru: 'У партнёра слишком низкий баланс, чтобы осуществлять одновременные звонки'
  },
  253: {
    en: 'Partner does not allow loss calls',
    ru: 'Партнер не разрешает вызовы с потерями'
  },
  254: {
    en: 'No Rates for Partner',
    ru: 'Нет доступных тарифов для партнёра'
  },
  255: {
    en: 'Destination is in blacklist',
    ru: 'Конечный пункт назначения в чёрном списке'
  },
  256: {
    en: 'Destination is not in whitelist',
    ru: 'Конечный пункт назначения находится не в белом списке'
  },
  257: {
    en: 'Calling Card expired',
    ru: 'Срок действия телефонной карты истек'
  },
  258: {
    en: 'Calling Card is not sold yet',
    ru: 'Телефонная карта ещё не продана'
  },
  259: {
    en: 'Calling Card is empty',
    ru: 'Телефонная карта пустая'
  },
  260: {
    en: `Destination is blocked in User's Tariff`,
    ru: 'Конечный пункт назначения заблокирован в тарифе пользователя'
  },
  261: {
    en: `Destination is blocked in Provider's Tariff`,
    ru: 'Конечный пункт назначения заблокирован в тарифе провайдера'
  },
  262: {
    en: 'Source number is in blacklist',
    ru: 'Номер источника в черном списке'
  },
  263: {
    en: 'Source number is not in whitelist',
    ru: 'Номер источника не в белом списке'
  },
  264: {
    en: 'No Rates for DID Owner',
    ru: 'Нет доступных тарифов для пользователя DID'
  },
  265: {
    en: 'Provider skipped because rate margin is lower than allowed',
    ru: 'Провайдер пропущен, поскольку тарифная маржа ниже допустимой'
  },
  266: {
    en: 'Provider skipped because AGI script returned timeout error',
    ru: 'Провайдер пропущен, потому что сценарий AGI вернул ошибку тайм-аута'
  },
  267: {
    en: 'Provider skipped because AGI script rejected this provider (AGI script status 0)',
    ru: 'Провайдер пропущен, потому что сценарий AGI отклонил этого провайдера (статус сценария AGI 0)'
  },
  268: {
    en: 'PDD limit reached',
    ru: 'Лимит PDD достигнут'
  },
  269: {
    en: `User's call rate is higher than allowed`,
    ru: 'Тариф звонка абонента выше разрешенного'
  },
  270: {
    en: `Reseller's call rate is higher than allowed`,
    ru: 'Тариф звонка реселлера выше разрешенного'
  },
  271: {
    en: `Partner's call rate is higher than allowed`,
    ru: 'Тариф звонка партнёра выше разрешенного'
  },
  272: {
    en: 'Provider skipped because the CallerID is in a Blacklist',
    ru: 'Провайдер пропущен поскольку ID абонента в чёрном списке'
  },
  273: {
    en: 'Provider skipped because the CallerID is not in a Whitelist',
    ru: 'Провайдер пропущен поскольку ID абонента не в белом списке'
  },
  274: {
    en: 'No rates for Provider',
    ru: 'Нет тарифов для Провайдера'
  },
  275: {
    en: 'Incorrect DID PIN',
    ru: 'Неверний PIN для DID'
  },
  276: {
    en: 'No Rates for DID Provider',
    ru: 'Нет тарифов для DID Провайдера'
  },
  277: {
    en: 'CallerID is in Global Blacklist',
    ru: 'CallerID находится в глобальном черном списке'
  },
  278: {
    en: 'CallerID is not in Global Whitelist',
    ru: 'CallerID находится не в глобальном белом списке'
  },
  279: {
    en: 'Destination number is in Global Blacklist',
    ru: 'Номер конечного пункта назначения находится в глобальном черном списке'
  },
  280: {
    en: 'Destination number is not in Global Whitelist',
    ru: 'Номер конечного пункта назначения находится не в глобальном белом списке'
  },
  281: {
    en: 'Provider skipped because the Destination number is in a Blacklist',
    ru: 'Провайдер пропущен так как номер конечного пункта назначения в чёрном списке'
  },
  282: {
    en: 'Provider skipped because the Destination number is not in a Whitelist',
    ru: 'Провайдер пропущен так как номер конечного пункта назначения не в белом списке'
  },
  283: {
    en: 'Source number is in DID blacklist',
    ru: 'Исходный номер находится в черном списке DID'
  },
  284: {
    en: 'DID is closed',
    ru: 'DID закрыт'
  },
  285: {
    en: 'Provider used its monthly call time limit',
    ru: 'Провайдер использовал весь месячный лимит времени вызовов'
  },
  286: {
    en: 'Device used its monthly call time limit',
    ru: 'Устройство использовало весь месячный лимит времени вызовов'
  },
  287: {
    en: 'Low balance for Device',
    ru: 'На устройстве низкий баланс'
  },
  288: {
    en: 'Reseller inbound call limit reached',
    ru: 'Достигнут лимит входящих вызовов реселлера'
  }
};
