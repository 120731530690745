import {styled} from '@mui/material/styles';
import {Box} from '@mui/material';

export const NotificationItem = styled(Box, {name: 'NotificationItem'})(({theme, notificationtype}) => ({
  position: 'fixed',
  bottom: theme.spacing(2.5),
  right: theme.spacing(2.5),
  minWidth: theme.spacing(18.75),
  minHeight: theme.spacing(5),
  padding: `${theme.spacing(0.625)} ${theme.spacing(3.5)} ${theme.spacing(0.625)} ${theme.spacing(1.25)}`,
  borderRadius: theme.spacing(0.5),
  background: theme.palette.white,
  display: 'flex',
  alignItems: 'center',
  zIndex: 2000,

  ...(notificationtype === 'error' && {
    background: '#ffd2d2',
    '& .MuiSvgIcon-root': {
      fill: theme.palette.red,
    }
  }),

  ...(notificationtype === 'info' && {
    background: '#fff2c3',
    '& .MuiSvgIcon-root': {
      fill: theme.palette.orange,
    }
  }),

  ...(notificationtype === 'success' && {
    background: '#e0ffe0',
    '& .MuiSvgIcon-root': {
      fill: theme.palette.green,
    }
  }),
}));

export const NotificationClose = styled('i', {name: 'NotificationClose'})(({theme}) => ({
  position: 'absolute',
  right: theme.spacing(1),
  top: theme.spacing(1),
  color: theme.palette.gray,
  cursor: 'pointer'
}));

export const NotificationTitle = styled('h3', {name: 'NotificationTitle'})(({theme}) => ({
  fontSize: theme.spacing(1.875),
  marginBottom: 0
}));
