import {styled} from "@mui/material";

export const PaymentProviderLabel = styled('div')(({theme}) => ({
  width: '100%',
  // height: theme.spacing(3.5),
  display: 'flex',
  alignItems: 'stretch',
  marginBottom: theme.spacing(1)
  // flexDirection: 'column',
}))

export const PaymentProviderName = styled('div', {name: 'PaymentProviderName'})(({theme}) => ({
  fontSize: theme.spacing(2),
  border: `${theme.spacing(.25)} solid ${theme.palette.primary.light}`,
  alignSelf: 'flex-start',
  borderRadius: `${theme.spacing(.5)} 0 0 ${theme.spacing(.5)}`,
  padding: `${theme.spacing(.125)} ${theme.spacing(1)}`,
  // backgroundColor: theme.palette.gray,
}))

export const PaymentProviderAddress = styled('div')(({theme}) => ({
  width: '100%',
  fontSize: theme.spacing(1.5),
  border: `${theme.spacing(.25)} solid ${theme.palette.primary.light}`,
  borderRadius: `0 ${theme.spacing(.5)} ${theme.spacing(.5)} 0`,
  padding: `${theme.spacing(.125)} ${theme.spacing(.5)}`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderLeft: 0,
}))

export const AmountVariantsContainer = styled('div')(({theme}) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  gap: theme.spacing(1),
  marginBottom: theme.spacing(2)
}))

export const AmountVariant = styled('div')(({theme}) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  border: `2px solid ${theme.palette.primary.main}`,
  borderRadius: theme.spacing(.5),
  cursor: 'pointer',
  transition: '.3s background-color',

  '&:hover': {
    backgroundColor: theme.palette.primary.light,
  },

  ...theme.typography.body1,
}))