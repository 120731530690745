import {useQuery} from "@tanstack/react-query";
import {getUserInfo} from "../api-service/get-user-info";
import {useAuth} from "../../../../hooks/use-auth";

export const useGetUserInfo = () => {
  const { accessToken } = useAuth();

  return useQuery({
    queryKey: ['user-info', accessToken],
    queryFn: getUserInfo,
    select: ({data}) => data,
  })
}