import {createSlice} from "@reduxjs/toolkit";

const initialState = [
  // {
  //   time: 9999,
  //   type: 'success',
  //   title: 'Test',
  //   description: 'This is a test'
  // }
]

const defaultNotificationVisibleTime = 5;

export const notificationsSlice = createSlice({
  name: 'notification-slice',
  initialState,
  reducers: {
    showNotification: (state, action) => {
      state.push({
        id: Date.now(),
        time: defaultNotificationVisibleTime,
        ...action.payload
      });
    },
    hideNotification: (state, action) => {
      return state.filter(notification => notification.id !== action.payload);
    }
  }
})

export const {showNotification, hideNotification} = notificationsSlice.actions;
export default notificationsSlice.reducer;
