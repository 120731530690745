import {createSlice} from "@reduxjs/toolkit";

import {loginUser, logoutUser} from "./auth.actions";

const initialState = {
  isLogged: false,
  data: {},
}

export const authSlice = createSlice({
  name: 'auth-slice',
  initialState,
  reducers: {
    logout: (state) => {
      state.isLogged = false;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(loginUser.fulfilled, (state) => {
      state.isLogged = true;
    });
    builder.addCase(logoutUser.fulfilled, (state) => {
      state.isLogged = false;
    })
  }
})

export const {logout} = authSlice.actions;
export default authSlice.reducer;
