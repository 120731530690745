import {useSelector} from "react-redux";
import {DataGrid} from '@mui/x-data-grid';
import {Box} from "@mui/material";
import PropTypes from 'prop-types';
import {splitStatusStringToObject} from '../../../../helpers/splitStatusStringToObject';
import {selectCallsSearchPeriod} from "../../store/call-search.selectors";
import {callsColumns} from './calls-table.utils';

export const CallsTable = ({rows}) => {
  const selectedPeriod = useSelector(selectCallsSearchPeriod);

  return (
    <DataGrid
      autoHeight
      columnVisibilityModel={{
        id: false
      }}
      columns={callsColumns}
      disableColumnFilter
      disableColumnMenu
      disableColumnSelector
      disableRowSelectionOnClick
      getRowClassName={(params) => {
        const {status} = splitStatusStringToObject(params.row.dispod);

        return status;
      }}
      getRowId={(row) => `${row.uniqueid}${row.provider_name}`}
      hideFooter={rows.length === 0}
      initialState={{
        pagination: {paginationModel: {pageSize: 15}},
      }}
      localeText={{noRowsLabel: (
          <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <p>Звонков за <b>{selectedPeriod.label.toLowerCase()}</b> по этому номеру не найдено.</p>
          </Box>
        )}}
      rows={rows}
      sx={{
        '& .MuiDataGrid-columnHeaders': {
          background: '#fff'
        },
        '& .MuiDataGrid-row.answered': {
          background: '#9aff9a38'
        },
        '& .MuiDataGrid-row.failed': {
          background: '#ff00001c'
        },
        '& .MuiDataGrid-row.busy': {
          background: '#ff81001f'
        },
        '& .MuiDataGrid-row.no-answer': {
          background: '#ff81001f'
        },
        '& .MuiDataGrid-cell': {
          fontSize: '12px'
        }
      }}
    />
  )
}

CallsTable.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      calldate2: PropTypes.string.isRequired,
      clid: PropTypes.string.isRequired,
      dst: PropTypes.string.isRequired,
      nice_billsec: PropTypes.string.isRequired,
      dispod: PropTypes.string.isRequired,
      user: PropTypes.string.isRequired,
      provider_name: PropTypes.string.isRequired,
      terminator_codec: PropTypes.string.isRequired,
      destination: PropTypes.string.isRequired,
      terminated_by: PropTypes.string.isRequired,
    })
  ).isRequired,
}
