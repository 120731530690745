import React from 'react';
import Modal from '@mui/material/Modal';
import PropTypes from 'prop-types';
import {useModal} from "../../hooks/use-modal";

import * as Styled from './modal-window.styled';

export const ModalWindow = ({id, children, ...props}) => {
  const {openedModals, closeModal} = useModal();
  const activeModal = openedModals.find(modal => modal.id === id);

  if (children.length > 1) {
    throw new Error('Modal window accept only one child');
  }

  const handleClose = () => closeModal(id);

  const windowInner = React.Children.map(children, child => {
    if (activeModal?.data && React.isValidElement(child)) {
      return React.cloneElement(child, { data: activeModal?.data });
    }
    return child;
  })

  return (
    <Modal
      aria-describedby="child-modal-description"
      aria-labelledby="child-modal-title"
      onClose={() => closeModal(id)}
      open={!!activeModal}
      slotProps={{
        root: {
          sx: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }
        }
      }}
      {...props}
    >
      <Styled.ModalContainer>
        {windowInner}
        <Styled.ModalCloseButton onClick={handleClose} />
      </Styled.ModalContainer>
    </Modal>
  )
}

ModalWindow.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
}