import {createSlice} from "@reduxjs/toolkit";
import {PERIODS} from "../../../components/shared/period-picker";

const initialState = {
  number: null,
  period: PERIODS.FIFTEEN_MIN,
}

export const callSearchSlice = createSlice({
  name: 'call-search',
  initialState,
  reducers: {
    setSearchPhone: (state, action) => {
      state.number = action.payload;
    },
    setSearchPeriod: (state, action) => {
      state.period = action.payload;
    }
  }
})

export const {setSearchPhone, setSearchPeriod} = callSearchSlice.actions;
export default callSearchSlice.reducer;