import {useSelector} from "react-redux"

export const useAuth = () => {
  const isUserLogged = useSelector(state => state.auth.isLogged);
  const accessToken = localStorage.getItem('accessToken');

  return {
    isUserLogged: isUserLogged && accessToken,
    accessToken,
  }
}