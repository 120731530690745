import PropTypes from 'prop-types';
import {Field, Form, Formik} from "formik";
import {Box, Button, InputAdornment, Link, Typography} from "@mui/material";

import * as Yup from 'yup';
import {RefillProviderFormVariants} from "./refill-provider-form-variants";
import {
  PaymentProviderAddress,
  PaymentProviderLabel,
  PaymentProviderName
} from "./refill-provider-form.styled";
import {useCreateProviderPayment} from "../../../modules/providers/api/api-hooks/use-create-provider-payment";
import {Loader} from "../../loader/loader";
import {useEffect} from "react";
import {showNotification} from "../../../store/notifications/notifications.slice";
import {useDispatch} from "react-redux";
import {useQueryClient} from "@tanstack/react-query";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ErrorIcon from '@mui/icons-material/Error';
import {TextField} from "formik-mui";
import {TextField as MuiTextField} from "@mui/material";
import {useCopy} from "../../../hooks/use-copy";

export const REFILL_PROVIDER_FORM_FIELDS = {
  AMOUNT: 'amount',
}

const MIN_AMOUNT = 1;
const MAX_AMOUNT = 5000;

const RefillProviderSchema = Yup.object().shape({
  [REFILL_PROVIDER_FORM_FIELDS.AMOUNT]: Yup.number()
    .min(MIN_AMOUNT,`Ниже минимальной суммы: ${MIN_AMOUNT}`)
    .max(MAX_AMOUNT, `Выше максимальной суммы суммы: ${MAX_AMOUNT}`)
})

export const RefillProviderForm = ({data: { provider, address }}) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient()
  const copyToClipboard = useCopy();

  const { mutate: createPaymentApi, isPending, data: payment, error } = useCreateProviderPayment({
    onError: ({response}) => {
      dispatch(showNotification({
        type: 'error',
        title: 'Ошибка пополнения!',
        description: response?.data?.message
      }));

      queryClient.invalidateQueries('providers');
    },
    onSuccess: ({response}) => {
      dispatch(showNotification({
        type: 'success',
        title: 'Пополнение успешно',
        description: 'Пополнение провайдера прошло успешно, уведомлеяем его в чате...'
      }))

      queryClient.invalidateQueries('providers');
    },
  });

  // useEffect(() => {
  //   return () => queryClient.invalidateQueries('createProviderPayment');
  // }, []);

  // const payment = {
  //   data: {
  //     status: "success",
  //     transaction: "3f020b465ee4f721ef3bf9f4c318d4d1d57067b52185"
  //   }
  // }

  const initialValues = {
    amount: 1
  }

  const copyHash = (hash) => {
    navigator.clipboard.writeText(hash);
    dispatch(showNotification({
      type: 'success',
      title: 'Хеш скопирован'
    }))
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={({amount}, formikHelpers) => {
        createPaymentApi({
          providerId: provider.morId,
          amount
        });

        formikHelpers.setSubmitting(false);
      }}
      validationSchema={RefillProviderSchema}
    >
      <Form>
        <Typography sx={{mb: 2}} variant="h6">Пополнение провайдера</Typography>

        <PaymentProviderLabel>
          <PaymentProviderName>{provider.name}:</PaymentProviderName>
          <PaymentProviderAddress>{provider.walletAddress || 'не указан'}</PaymentProviderAddress>
        </PaymentProviderLabel>

        <Field
          component={TextField}
          fullWidth
          name={REFILL_PROVIDER_FORM_FIELDS.AMOUNT}
          placeholder="Сумма"
          sx={{mb: 1}}
          type="number"
        />

        <RefillProviderFormVariants />

        {isPending && <Loader />}

        {!isPending && !payment && (
          <Button
            type="submit"
            variant="contained"
            disabled={!provider.walletAddress}
          >
            Пополнить
          </Button>
        )}

        {!isPending && payment && (
          <>
            <Typography align="center" variant="h6">Пополнение успешно</Typography>
            <MuiTextField
              id="input-with-icon-textfield"
              label="Хэш транзации"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <ContentCopyIcon />
                  </InputAdornment>
                ),
              }}
              variant="standard"
              value={payment.data.transaction}
              onClick={() => copyToClipboard(payment.data.transaction)}
              fullWidth
              sx={{mb: 2}}
            />

            <Link
              variant="body2"
              href={`https://tronscan.org/#/transaction/${payment.data.transaction}`}
              target="_blank"
            >
              Открыть в TronLink
            </Link>
          </>
        )}

        {!isPending && error && <Typography sx={{mt: 2, color: 'red'}}>Ошибка пополнения</Typography>}

        {!provider.walletAddress && (
          <Box sx={{display: 'flex', gap: '8px', alignItems: 'center', my: 1}}>
            <ErrorIcon sx={{color: 'red'}}/>
            <Typography>Не указан кошелек провайдера, пополнение невозможно.</Typography>
          </Box>
        )}
        {provider.chats.length === 0 && (
          <Box sx={{display: 'flex', gap: '8px', alignItems: 'center', my: 1}}>
            <ErrorIcon sx={{color: 'orange'}}/>
            <Typography>Не авторизован бот у провайдера, уведомление невозможно.</Typography>
          </Box>
        )}
        {provider.usernames.length === 0 && (
          <Box sx={{display: 'flex', gap: '8px', alignItems: 'center', my: 1, maxWidth: '400px'}}>
            <ErrorIcon sx={{color: 'orange'}}/>
            <Typography>Не указаны ответственные лица за пополнение у провайдера (кого тегать), уведомление невозможно.</Typography>
          </Box>
        )}
      </Form>
    </Formik>
  )
}

RefillProviderForm.propTypes = {
  data: PropTypes.shape({
    provider: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
    address: PropTypes.string.isRequired,
  })
}
