import {Grid, Typography} from "@mui/material";
import {BasicTextField} from "../../../../../components/elements/form/basic-text-field/basic-text-field";
import {BasicCheckboxField} from "../../../../../components/elements/form/basic-checkbox-field/basic-checkbox-field";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import {FormSection} from "../../../../../components/layout/form-section/form-section";
import {useCopy} from "../../../../../hooks/use-copy";
import {useFormikContext} from "formik";
import {AutoComplete} from "../../../../../components/elements/form/auto-complete/auto-complete";
import {usePermissionAccess} from "../../../../../hooks/use-permission-access";
import {PERMISSIONS} from "../../../../../../../app/permissions/permissions";

export const ProviderSettingsFormFields = ({provider}) => {
  const { values } = useFormikContext();
  const { hasPermissions } = usePermissionAccess();
  const copyToClipboard = useCopy();

  return (
    <FormSection>
      <Typography variant="h6" sx={{mb: 1}}>Settings</Typography>

      <Grid container spacing={2}>
        <Grid item xs={4}>
          <BasicTextField
            placeholder="Provider name"
            name="name"
            label="Provider name"
          />
        </Grid>
        <Grid item xs={4}>
          <BasicTextField
            placeholder="Balance limit"
            name="balance_limit"
            label="Balance limit"
          />
        </Grid>
        <Grid item xs={4}>
          <BasicTextField
            placeholder="Device name"
            label="Device name"
            name="device_id"
          />
        </Grid>
        <Grid item xs={4}>
          <BasicCheckboxField
            name="notificationsEnabled"
            label="Provider payment notification"
          />
        </Grid>
        <Grid item xs={4}>
          <BasicTextField
            placeholder="Port"
            label="Port"
            name="port"
          />
        </Grid>
        <Grid item xs={4}>
          <BasicTextField
            placeholder={'server_ip'}
            name={'server_ip'}
            label={'server_ip'}
          />
        </Grid>
        <Grid item xs={4}>
          <BasicTextField
            placeholder={'tech'}
            name={'tech'}
            label={'tech'}
          />
        </Grid>
        <Grid item xs={4}>
          <BasicTextField
            placeholder={'telegramToken'}
            name={'telegramToken'}
            label={'Telegram token'}
            icon={<ContentCopyIcon />}
            // iconPosition="end"
            onClick={() => copyToClipboard(values.telegramToken)}
            disabled
          />
        </Grid>
        <Grid item xs={4}>
          <BasicTextField
            placeholder={'terminator_id'}
            name={'terminator_id'}
            label={'terminator_id'}
          />
        </Grid>
        <Grid item xs={4}>
          <Typography sx={{mb: 1}}>Provider supports TG</Typography>
          <AutoComplete
            name="usernames"
            label=""
            editable
            options={provider.usernames.map(item => ({title: item, value: item}))}
          />
        </Grid>
        <Grid item xs={8}>
          <BasicTextField
            placeholder="Wallet address"
            name="walletAddress"
            label="Wallet address"
            disabled={!hasPermissions(PERMISSIONS.PROVIDER.WALLET_EDIT)}
          />
        </Grid>
      </Grid>
    </FormSection>
  )
}