import {createSlice} from "@reduxjs/toolkit";

const initialState = {
  playingCall: null
}

export const playerSlice = createSlice({
  name: 'player-slice',
  initialState,
  reducers: {
    playCall: (state, action) => {
      state.playingCall = action.payload;
    },
  }
})

export const {playCall} = playerSlice.actions;
export default playerSlice.reducer;
