import {Button, Typography, Box} from '@mui/material';
import {TextField} from 'formik-mui';
import {Field} from "formik";
import {PeriodPicker} from "../../../../../components/shared/period-picker";
import {selectCallsSearchPeriod, selectSearchPeriod} from "../../../store/call-search.selectors";
import {useDispatch, useSelector} from "react-redux";
import {setSearchPeriod} from "../../../store/call-search.slice";
import {CallsSearchPeriod} from "../../calls-search-period/calls-search-period";

export const CallSearchFormFields = () => {
  return (
    <Box sx={{mb: 2}}>
      <Typography sx={{mb: 4}} variant="h5">Поиск по номеру</Typography>

      <Box sx={{display: 'flex', maxWidth: '500px'}}>
        <Field
          component={TextField}
          name="phone"
          placeholder="+79012345678"
          sx={{width: '100%', mr: 0.5,}}
        />

        <Button
          disableElevation
          type="submit"
          variant="contained"
        >
          Поиск
        </Button>
      </Box>

      <CallsSearchPeriod />
    </Box>
  )
}
