import {createContext, useMemo, useState} from "react";
import {RefillProviderModal} from "../components/modals/refill-provider-modal/refill-provider-modal";

export const ModalContext = createContext({
  openModal: () => {},
  closeModal: () => {},
  openedModals: [],
});

export const ModalProvider = ({children}) => {
  const [openedModals, setOpenedModals] = useState([]);

  const openModal = (modal) => {
    if (typeof modal === 'string') {
      return setOpenedModals(modals => [...modals, {
        id: modal
      }]);
    }

    setOpenedModals(modals => [...modals, modal]);
  };

  const closeModal = (id) => {
    setOpenedModals(modals => modals.filter(modal => modal.id !== id));
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const modalContextValue = useMemo(() => ({
    openModal,
    closeModal,
    openedModals
  }))

  return (
    <ModalContext.Provider value={modalContextValue}>
      {children}

      {/* MODALS */}
      <RefillProviderModal />
      {/* MODALS END */}
    </ModalContext.Provider>
  )
}