import {Typography} from "@mui/material";
import {LoginForm} from "../forms/login-form";

import * as Styled from "./login-sidebar.styled";

import logo from '../../../../assets/images/logo@2x.png';

export const LoginSidebar = () => {
  return (
    <Styled.LoginSidebarContainer>
      <Typography sx={{mb: 2}} variant="h5">RocketCall</Typography>
      <Styled.LoginLogo  src={logo}/>
      <Typography sx={{mb: 2}} variant="h7">Login</Typography>
      <LoginForm/>
    </Styled.LoginSidebarContainer>
  )
}