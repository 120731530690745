import {useQuery} from "@tanstack/react-query";
import {getRecording} from "../api-service/get-recording";

export const useGetRecording = (call, options = {}) => {
  const {uniqueid, server_id} = call || {};

  return useQuery({
    queryKey: ['recording', uniqueid, server_id],
    queryFn: () => getRecording(uniqueid, server_id),
    select: ({data}) => data,
    enabled: !!call,
    ...options
  })
}