import {ModalWindow} from "../../modal-window/modal-window";
import {MODAL_IDS} from "../constants";
import {RefillProviderForm} from "../../forms";

export const RefillProviderModal = () => {
  return (
    <ModalWindow id={MODAL_IDS.REFILL_PROVIDER}>
      <RefillProviderForm />
    </ModalWindow>
  )
}