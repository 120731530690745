import {styled} from "@mui/material";

export const PeriodPickerWrapper = styled('div')(({theme}) => ({
  margin: `${theme.spacing(2)} 0`,
  padding: theme.spacing(1),
  border: `2px solid ${theme.palette.primary.light}`,
  alignSelf: 'flex-start',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: theme.spacing(.5)
}))