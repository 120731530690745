import {useQuery, useQueryClient} from "@tanstack/react-query";
import {syncUsers} from "../api-service/sync-users";

export const useSyncUsers = (onSuccess) => {
  const queryClient = useQueryClient()

  return useQuery({
    queryKey: ['sync-users'],
    queryFn: () => {
      return new Promise((resolve, reject) => {
        syncUsers()
          .then(syncData => {
            queryClient.invalidateQueries({queryKey: ['users']});
            onSuccess?.(syncData.data);
            resolve(syncData);
          })
          .catch(error => reject(error))
      })
    },
    select: ({data}) => {
      return data;
    },
    enabled: false,
  })
}
