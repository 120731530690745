import {CallSearch} from "../../modules/call-search/components/forms/call-search/call-search-form";
import {CallSearchResults} from "../../modules/call-search/components/call-search-results/call-search-results";

export const CallSearchPage = () => {
  return (
    <>
      <CallSearch/>
      <CallSearchResults/>
    </>
  )
}