import HomeIcon from "@mui/icons-material/Home";
import CallIcon from "@mui/icons-material/Call";
import PublicIcon from "@mui/icons-material/Public";
import GroupIcon from "@mui/icons-material/Group";
import HistoryIcon from '@mui/icons-material/History';

import {ROUTES} from "../../../system/router/routes";

export const sidebarLinks = [
  {
    route: ROUTES.DASHBOARD,
    icon: <HomeIcon />,
    label: 'Главная',
    subData: null,
    access: null,
  },
  {
    route: ROUTES.CALL_SEARCH,
    icon: <CallIcon />,
    label: 'Поиск звонка',
    subData: null,
    access: null,
  },
  {
    route: ROUTES.PROVIDERS,
    icon: <PublicIcon />,
    label: 'Провайдеры',
    // isSectionHeading: true,
    subData: [
      {
        route: ROUTES.PROVIDERS,
        icon: <PublicIcon />,
        label: 'Все провайдеры',
      },
      {
        route: ROUTES.PROVIDERS_PAYMENTS,
        icon: <HistoryIcon />,
        label: 'История пополнений',
      },
    ],
    access: null,
  },
  {
    route: ROUTES.USERS,
    icon: <GroupIcon />,
    label: 'Пользователи',
    subData: null,
    access: null,
  }
]