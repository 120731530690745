import {Box, Button, Link, Typography} from '@mui/material';
import {Loader} from '../../../components/loader/loader'
import {useGetAllProviders} from "../../../modules/providers/api/api-hooks/use-get-all-providers";
import {ProvidersTable} from "../../../modules/providers/components/providers-table/providers-table";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import {useSyncProviders} from "../../../modules/providers/api/api-hooks/use-sync-providers";
import {useEffect} from "react";
import {httpService} from "../../../system/api/http-service";
import {useGetProvidersPayments} from "../../../modules/providers/api/api-hooks/use-get-providers-payments";
import {providerColumns} from "../../../modules/providers/components/providers-table/providers-table.utils";
import * as Styled from "../../../modules/providers/components/providers-table/providers-table.styled";
import dayjs from "dayjs";

const ProviderCell = ({providerId}) => {
  const {data: providers} = useGetAllProviders();
  const provider = providers?.find(provider => provider.morId === +providerId);

  return provider?.name;
}

export const providersPaymentsColumns = [
  {
    field: 'id',
    flex: 1,
    sortable: false,
  },
  {
    headerName: 'Провайдер',
    field: 'additionalInfo.name',
    flex: 1,
    sortable: false,
    valueGetter: param => param.row.additionalInfo.name
  },
  {
    headerName: 'Инициатор',
    field: 'additionalInfo.initiator',
    flex:.5,
    sortable: false,
    valueGetter: param => param.row.additionalInfo.initiator
  },
  {
    headerName: 'Сумма',
    field: 'amount',
    flex: .5,
    sortable: false,
    headerAlign: 'center',
    align: 'center',
  },
  {
    headerName: 'Токен',
    field: 'token',
    flex: .5,
    sortable: false,
  },
  {
    headerName: 'Дата',
    field: 'transactionDate',
    flex: .8,
    sortable: false,
    renderCell: ({row}) => {
      const callDate = dayjs.utc(row.transactionDate).tz('Europe/Kiev');

      return (
        <>{callDate.format('DD.MM.YYYY HH:mm:ss')}</>
      )
    }
  },
  {
    headerName: 'Целевой адрес',
    field: 'toAddress',
    flex: 1,
    sortable: false,
  },
  {
    headerName: 'Хэш',
    field: 'hash',
    flex: 1,
    sortable: false,
    renderCell: ({row}) => {
      return <Typography
        component={Link}
        href={`https://tronscan.org/#/transaction/${row.hash}`}
        target="_blank"
        noWrap
      >
        {row.hash}
      </Typography>
    }
  },
]

export const ProviderPaymentListPage = () => {
  const { data: providersPayments, isFetching} = useGetProvidersPayments();

  return (
    <>
      <Typography sx={{mb: 4}} variant="h5">История пополнений провайдеров</Typography>

      {isFetching ? (
        <Loader />
      ) : (
        providersPayments ? (
          <Styled.ProvidersTableDataGrid
            autoHeight
            columnVisibilityModel={{
              id: false
            }}
            columns={providersPaymentsColumns}
            rows={providersPayments.data.transactions}
            initialState={{
              sorting: {
                sortModel: [{ field: 'balance', sort: 'desc' }],
              },
              pagination: {
                paginationModel: { pageSize: 10, page: 0 },
              },
            }}
            getRowId={row => row._id}
          />
          // <ProvidersTable providers={providers}/>
        ) : (
          <>Error. No data available</>
        )
      )}
    </>
  )
}