import {useState} from "react";
import {useDispatch} from "react-redux";
import {Box, Button, Typography, TextField} from '@mui/material';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import {Loader} from '../../components/loader/loader'
import {useGetAllUsers} from "../../modules/users/api/api-hooks/use-get-all-users";
import {UsersTable} from "../../modules/users/components/users-table/users-table";
import {useSyncUsers} from "../../modules/users/api/api-hooks/use-sync-users";

import {showNotification} from "../../store/notifications/notifications.slice";

export const UsersPage = () => {
  const [userLogin, setUserLogin] = useState('');
  const dispatch = useDispatch();

  const {data: users, isFetching: isUsersFetching, refetch: getUsersApi } = useGetAllUsers({
    filters: {
      username: userLogin
    }
  });
  const {isFetching: isSyncFetching, refetch: syncUsersApi } = useSyncUsers((data) => {
    const { created, deleted } = data.result;

    dispatch(showNotification({
      type: 'success',
      title: 'Синхронизация выполена',
      description: (
        <>
          {created.length ? <p>Добавлены: {created.join(',')}</p> : ''}
          {deleted.length ? <p>Удалены: {deleted.join(',')}</p> : ''}

          {(created.length === 0 && deleted.length === 0) && 'Данные обновлены'}
        </>
      )
    }))
  });

  return (
    <>
      <Typography sx={{mb: 4}} variant="h5">Пользователи</Typography>

      <Box sx={{mb: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
        <Box sx={{width: (theme) => theme.spacing(18.75)}}>
          <Button
            onClick={syncUsersApi}
            variant="contained"
            size="small"
            fullWidth
          >
            {isSyncFetching ? <Loader size={18} color="white"/> : 'Синхронизировать'}
          </Button>
        </Box>

        <Button
          variant="outlined"
          size="small"
          onClick={getUsersApi}
          sx={{color: 'dark4'}}
        >
          <AutorenewIcon/>
        </Button>
      </Box>

      <TextField
        fullWidth
        type="text"
        size="small"
        placeholder="Поиск по логину пользователя"
        sx={{
          my: 2,
        }}
        onChange={(event) => setUserLogin(event.target.value)}
      />


      {isUsersFetching && <Loader/>}
      {!isUsersFetching && users && <UsersTable users={users}/>}
    </>
  )
}
