import {createContext, useCallback, useMemo, useState} from "react";
import {ShowNotification} from "../components/show-notification/show-notification";
import {showNotification as showNotificationAction} from "../store/notifications/notifications.slice";
import {useDispatch} from "react-redux";

export const NotificationsContext = createContext({});

export const NotificationsProvider = ({children}) => {
  const dispatch = useDispatch();
  const [activeNotification, setActiveNotification] = useState(null);

  const showNotification = useCallback((payload) => {
    dispatch(showNotificationAction(payload))
  }, []);

  const hideNotification = () => {
    setActiveNotification(null)
  }

  const contextValue = useMemo(() => ({
    showNotification,
    hideNotification,
    activeNotification
  }), activeNotification);

  return (
    <NotificationsContext.Provider value={contextValue}>
      {children}
      <ShowNotification/>
    </NotificationsContext.Provider>
  )
}