export const filterUsers = (filters, users) => {
  if (!filters) {
    return users;
  }

  const isFiltersNotEmpty = Object.values(filters).some(
    (filter) => filter.length > 0
  );

  if (isFiltersNotEmpty) {
    return users.filter((user) => {
      const matches = [];

      for (const filterProp in filters) {
        const userProp = String(user[filterProp]).toLowerCase();
        const filterRule = String(filters[filterProp]).toLowerCase();

        matches.push(userProp.includes(filterRule));
      }

      return matches.every((isMatch) => isMatch);
    });
  }

  return users;
}