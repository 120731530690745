import {Field, Form, Formik} from 'formik';
import {useDispatch} from 'react-redux';
import {TextField} from 'formik-mui';
import {Button} from "@mui/material";
import {loginUser} from "../../../../store/auth/auth.actions";

export const LoginForm = () => {
  const dispatch = useDispatch()

  const handleSubmit = (values, helpers) => {
    dispatch(loginUser(values));
    helpers.setSubmitting(false);
  }

  return (
    <Formik
      initialValues={{}}
      onSubmit={handleSubmit}
    >
      <Form>
        <Field
          component={TextField}
          fullWidth
          id="username"
          name="username"
          placeholder="Login"
          sx={{mb: 1}}
        />
        <Field
          component={TextField}
          fullWidth
          name="password"
          placeholder="Password"
          sx={{mb: 2}}
          type="password"
        />

        <Button
          disableElevation
          fullWidth
          type="submit"
          variant="contained"
        >
          Log in
        </Button>
      </Form>
    </Formik>
  )
}