import React from 'react';
import ReactDOM from 'react-dom/client';
import {Provider as ReduxProvider} from 'react-redux';
import {PersistGate} from 'redux-persist/integration/react';
import {RouterProvider} from 'react-router-dom';
import {ThemeProvider} from '@mui/material/styles';
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'

import {router} from './system/router/router';
import {persistor, store} from './store/store';
import {theme} from './system/styles/theme';
import App from './App.jsx';

import './index.scss';


const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 5 * 60 * 60 * 1000,
      retry: 1,
    }
  }
})

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <ReduxProvider store={store}>
    <QueryClientProvider client={queryClient}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={theme}>
          <RouterProvider router={router}>
            <App/>
          </RouterProvider>
        </ThemeProvider>
      </PersistGate>
    </QueryClientProvider>
  </ReduxProvider>
);

