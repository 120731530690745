import {styled} from "@mui/material";
import {Box} from "@mui/material";
import ListItem from "@mui/material/ListItem";

export const SidebarContainer = styled('div', {name: 'SidebarContainer'})(({theme}) => ({
  minWidth: theme.spacing(37.5),
  maxWidth: theme.spacing(37.5),
  height: ["100vh", theme.spacing(62.5)],
  minHeight: "100vh",
  display: "flex",
  flexDirection: "column",
  padding: `${theme.spacing(5)} 0`,
  background: theme.palette.white,
  position: "sticky",
  top: "0"
}));

export const SidebarLogo = styled('div', {name: 'SidebarLogo'})(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  userSelect: 'none',
  alignSelf: 'center',
  marginBottom: theme.spacing(4.375),

  'img': {
    width: theme.spacing(6.25),
    marginRight: theme.spacing(1.25),
  },

  'p': {
    fontSize: theme.spacing(3.25),
  },
}));

export const SidebarMenu = styled('div', {name: 'SidebarMenu'})({
  display: 'flex',
  flexDirection: 'column',
});

export const SidebarMenuItem = styled(ListItem, {name: 'SidebarMenuItem'})(({isActive, isNested, theme}) => ({
  color: theme.palette.semiBlack,
  fontSize: theme.spacing(2.25),
  textDecoration: 'none',
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  borderLeft: `${theme.spacing(0.375)} solid transparent`,
  transition: '0.1s transform',
  background: 'transparent',
  width: '100%',
  padding: 0,

  '& .MuiPaper-root': {
    background: 'transparent',
    boxShadow: 'none',
    width: '100%',
  },

  '&:before': {
    content: '\'\'',
    display: 'block',
    position: 'absolute',
    width: theme.spacing(6.25),
    height: '100%',
    background: 'rgb(255 117 124 / 8%)',
    borderTopRightRadius: theme.spacing(0.625),
    borderBottomRightRadius: theme.spacing(0.625),
    transform: `translateX(-${theme.spacing(10)})`,
    transition: '0.2s transform',
  },
}));

export const SidebarFooter = styled('div', {name: 'SidebarFooter'})(({theme}) => ({
  display: "flex",
  alignItems: "center",
  width: "100%",
  padding: `${theme.spacing(5)} ${theme.spacing(5)} 0`,
  marginTop: "auto"
}));

export const SidebarUserInfo = styled('div', {name: 'SidebarUserInfo'})(({theme}) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",

  span: {
    "&:nth-child(2)": {
      fontSize: theme.spacing(1.25),
      color: theme.palette.gray
    }
  }
}));

export const FooterIcon = styled(Box, {name: 'FooterIcon'})(({theme}) => ({
  width: theme.spacing(6.25),
  height: theme.spacing(4.375),
  borderRadius: theme.spacing(0.625),
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: theme.palette.semiWhite,
  marginRight: theme.spacing(1.25)
}));
