import {useQuery} from "@tanstack/react-query"
import {useSelector} from "react-redux";

import {getCalls} from "../api-service/get-calls";

import {selectCallsSearchPeriod } from "../../store/call-search.selectors";
import {prepareCalls} from "../../helpers/prepare-calls";

export const useGetCallsSearch = (searchPhone) => {
  const period = useSelector(selectCallsSearchPeriod);

  return useQuery({
    queryKey: ['calls', searchPhone, period.value],
    queryFn: () => getCalls(searchPhone, period.value),
    enabled: Boolean(searchPhone),
    select: ({data}) => prepareCalls(data.calls),
    retry: false,
    keepPreviousData: true
  })
}