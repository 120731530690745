import {styled, Typography} from "@mui/material";

export const DashboardStatWrapper = styled('div', {name: 'DashboardStatWrapper'})({
  display: 'flex',
  flexDirection: 'column'
})

export const DashboardStatHeading = styled(Typography, {name: 'DashboardStatHeading'})(({theme}) => ({
  marginBottom: theme.spacing(3.5),
  fontSize: theme.spacing(2.25),
  fontWeight: 500,
}))

export const DashboardStatGroup = styled('div', {name: 'DashboardStatGroup'})({
  display: 'flex'
})

export const DashboardStatItem = styled('div', {name: 'DashboardStatItem'})(({theme}) => ({
  width: theme.spacing(25),
  height: theme.spacing(10),
  background: theme.palette.white,
  borderRadius: theme.spacing(0.625),
  padding: theme.spacing(2.5),
  marginBottom: theme.spacing(2.5),
  display: 'flex',
  alignItems: 'center'
}))
