import {useQuery, useQueryClient} from "@tanstack/react-query";
import {syncProviders} from "../api-service/sync-providers";

export const useSyncProviders = (onSuccess) => {
  const queryClient = useQueryClient()

  return useQuery({
    queryKey: ['sync-providers'],
    queryFn: () => {
      return new Promise((resolve, reject) => {
        syncProviders()
          .then(syncData => {
            queryClient.invalidateQueries({queryKey: ['providers']});
            onSuccess?.(syncData.data);
            resolve(syncData);
          })
          .catch(error => reject(error))
      })
    },
    select: ({data}) => {
      return data;
    },
    enabled: false,
  })
}
