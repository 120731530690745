import Typography from "@mui/material/Typography";
import {Loader} from "../../../../components/loader/loader"

import * as Styled from "./dashboard-header.styled";
import {useGetUserInfo} from "../../../user/api/api-hooks/use-get-user-info";


export const DashboardHeader = ({userData}) => {
  const { data: user } = useGetUserInfo();
  const userType = userData?.usertype

  return(
    <Styled.DashboardGreeting>
      {userData ? (
        <>
          <Typography sx={{mb: 4}} variant="h5">Привет, {user?.username}!</Typography>
          {userType === ('admin' || 'accountant') && (<Typography>Вот ваша быстрая статистика по звонкам:</Typography>)}
          {userType === 'user' && (<Typography>Быстрая статистика вам недоступна</Typography>)}
        </>
      ) : (
        <Loader />
      )}
    </Styled.DashboardGreeting>
  )
}
