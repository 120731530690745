import {useMutation} from "@tanstack/react-query";
import {createProviderPayment} from "../api-service/create-provider-payment";

export const useCreateProviderPayment = ({...options}) => {
  return useMutation({
    ...options,
    // mutationKey: ['createProviderPayment'],
    mutationFn: ({providerId, amount}) => createProviderPayment(providerId, amount),
    select: ({data}) => {
      return data;
    },
  })
}