import {isDev} from "../system/helpers/environment";
import frontendConfig from '../../frontend.config';

const baseUrl = isDev
  ? frontendConfig.localDomain
  : frontendConfig.host;

export class RocketApi {
  static async loginUser({username, password}) {
    const response = await fetch(`${baseUrl}/api/auth/login`, {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify({
        username, password
      }),
      headers: {
        'Content-type': 'application/json'
      }
    });
    const data = await response.json();

    localStorage.setItem('accessToken', data.accessToken);
    localStorage.setItem('refreshToken', data.refreshToken);

    return data;
  }

  static async refreshToken() {
    const response = await fetch(`${baseUrl}/api/auth/refresh`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        // 'Authorization': `Bearer ${refreshTokenKey}`,
        'Content-type': 'application/json'
      }
    });

    const {refreshToken, accessToken} = await response.json();

    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('refreshToken', refreshToken);

    return accessToken;
  }

  static async logoutUser() {
    const accessToken = localStorage.getItem('accessToken');

    const response = await fetch(`${baseUrl}/api/auth/logout`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });

    const data = await response.json();

    sessionStorage.removeItem('accessToken');
    sessionStorage.removeItem('refreshToken');

    return data;
  }
}