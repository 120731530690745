import {configureStore} from "@reduxjs/toolkit";
import {persistStore, persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage/session';

import user from './user/user.slice';
import auth from './auth/auth.slice';
import notifications from './notifications/notifications.slice';
import player from '../modules/audio-player/store/player.slice';
import callSearch from '../modules/call-search/store/call-search.slice';

const persistConfig = {
  key: 'root',
  storage,
}

const persistedAuth = persistReducer(persistConfig, auth);
const persistedCallSearch = persistReducer(persistConfig, callSearch);

export const store = configureStore({
  reducer: {
    user,
    player,
    notifications,
    auth: persistedAuth,
    callSearch: persistedCallSearch,
  },
  devTools: true,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false
  }),
})

export const persistor = persistStore(store)
