import {styled} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

export const ModalContainer = styled('div')(({theme}) => ({
  position: 'relative',
  // top: '50%',
  // left: '50%',
  // transform: 'translate(-50%, -50%)',
  minWidth: theme.spacing(40),
  backgroundColor: theme.palette.white,
  padding: theme.spacing(2),
  borderRadius: theme.spacing(.5),
}));

export const ModalCloseButton = styled(CloseIcon)(({theme}) => ({
  position: 'absolute',
  top: theme.spacing(1),
  right: theme.spacing(1),
  cursor: 'pointer',
}));
