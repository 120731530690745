import {Form, Formik} from "formik"
import {CallSearchFormFields} from "./call-search-form-fields";
import {useDispatch, useSelector} from "react-redux";
import {useSearchParams} from "react-router-dom";
import {useEffect} from "react";
import {setSearchPhone} from "../../../store/call-search.slice";
import {selectSearchPhone} from "../../../store/call-search.selectors";

export const CallSearch = () => {
  const dispatch = useDispatch();
  const previousSearchPhone = useSelector(selectSearchPhone)
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <Formik
      // initialValues={{ phone: '79523768732' }}
      enableReinitialize
      initialValues={{phone: previousSearchPhone || ''}}
      onSubmit={(values, actions) => {
        actions.setSubmitting(false);
        searchParams.set('phone', values.phone);
        setSearchParams(searchParams);
        dispatch(setSearchPhone(values.phone))
      }}
    >
      <Form>
        <CallSearchFormFields/>
      </Form>
    </Formik>
  )
}
