import {createTheme} from '@mui/material/styles';

const colors = {
  primary: {
    main: '#ffa600',
    light: '#fdce88',
    dark: '#cb8600',
  },
  white: '#fff',

  dark0: '#000000',
  dark1: '#1c1c1c',
  dark2: '#2a2a2a',
  dark3: '#3b3b3b',
  dark4: '#464646',

  semiGray: '#434343',
  lightGray: '#f6f0e5',
  semiWhite: '#e7e7e7',
  gray: '#ccc',
  lightOrange: '#ff8671',
  red: '#ff0000',
  green: '#008000',
  blue: '#0000ff',
  semiBlack: '#06152B',
  orange: '#d96b38',
  tableBg: '#fff3e4',
}

export const theme = createTheme({
  palette: colors,

  typography: {
    fontFamily: [
      'Open Sans',
      'Arial',
      'sans-serif',
    ].join(","),

    body1: {
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "22px"
    },
    body16: {
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "24px"
    },
    body14regular: {
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "22px"
    },
    body14semibold: {
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: "600",
      lineHeight: "22px"
    },
    h3: {
      color: "#1C1C28",
      fontSize: "28px",
      fontStyle: "normal",
      fontWeight: "700",
      lineHeight: "38px"
    },
    h4: {
      color: "#1C1C28",
      fontSize: "24px",
      fontStyle: "normal",
      fontWeight: "700",
      lineHeight: "32px"
    }
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {    //input focus border
            border: `1px solid ${colors.primary.main}`,
            boxShadow: `0px 0px 0px 2px ${colors.primary.main}`
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {  //input hover
            border: `1px solid ${colors.primary.main}`,
          },
        },
        input: {
          padding: "8px 12px",
        },
        notchedOutline: {
          border: "1px solid #C7C9D9", //input default border
        }
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          height: '40px',
          '& .MuiInputBase-input': {
            padding: '8px'

          }
        },
        input: {
          padding: '8px'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
        sizeLarge: {
          padding: "8px 16px",
          fontSize: "16px",
          fontWeight: "600",
          lineHeight: "24px",
        }
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginLeft: 0,
        }
      }
    }
  }
})
