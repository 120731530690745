import {styled} from '@mui/material/styles';

export const LoginPageGraphic = styled('main', {name: 'LoginPageGraphic'})(({theme}) => ({
  width: 'calc(100% - 350px)',
  height: '100vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  'img': {
    width: '100%',
    maxWidth: theme.spacing(62.5),
    userSelect: 'none',
  }
}));