import {useEffect, useState} from "react";
import {useDispatch, useSelector} from 'react-redux';
import AudioPlayer from 'react-h5-audio-player';
import {Box, Typography} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {playCall} from "../store/player.slice";
import {useGetRecording} from "../api/api-hooks/use-get-recording";

import * as Styled from './audio-player.styled';
import 'react-h5-audio-player/lib/styles.css';

export const CallPlayer = () => {
  const dispatch = useDispatch();
  const playingCall = useSelector((state) => state.player.playingCall);
  const {data, isLoading} = useGetRecording(playingCall);
  const [audio, setAudio] = useState(null);

  useEffect(() => {
    const objectUrl = URL.createObjectURL(new Blob([data], {type: "application/octet-stream"}));

    setAudio(objectUrl);

    return () => URL.revokeObjectURL(objectUrl)
  }, [data]);

  const handleAudioLoaded = (event) => {
    event.target.play()
  }

  const clearPlayingCall = () => {
    dispatch(playCall(null));
  }

  useEffect(() => {
    return () => {
      clearPlayingCall();
    }
  }, []);

  return playingCall && (
    <Styled.AudioPlayerWrapper>
      <Box sx={{mb: 2}}>
        <CloseIcon onClick={clearPlayingCall} sx={{cursor: 'pointer'}}/>

        {isLoading
          ? <Typography>Loading...</Typography>
          : (
            <>
              <Typography>Пользователь: {playingCall?.user}</Typography>
              <Typography>Номер: {playingCall?.dst}</Typography>
              <Typography>Дата: {playingCall?.calldate2}</Typography>
            </>
          )}
      </Box>
      <AudioPlayer
        onLoadedData={handleAudioLoaded}
        src={data ? audio : ''}
      />
    </Styled.AudioPlayerWrapper>
  )
}
