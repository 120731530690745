import {useDispatch} from "react-redux";
import {NavLink} from 'react-router-dom';
import { useEffect } from "react";
import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';
import {Typography} from "@mui/material";
import {setUserData} from "../../../store/user/user.slice";
import {useGetUserInfo} from "../../../modules/user/api/api-hooks/use-get-user-info";
import {logoutUser} from "../../../store/auth/auth.actions";

import logo from '@assets/images/logo@2x.png';
import {sidebarLinks} from "./sidebar-links";

import * as Styled from "./sidebar.styled";
import {SidebarMenuItems} from "./sidebar-menu-items/sidebar-menu-items";

export const Sidebar = () => {
  const {data: userData} = useGetUserInfo();
  const dispatch = useDispatch();

  useEffect(() => {
    if(userData){
      dispatch(setUserData(userData))
    }
  }, [dispatch, userData]);

  return (
    <Styled.SidebarContainer>
      <Styled.SidebarLogo>
        <img alt="" src={logo}/>
        <p>RocketCall</p>
      </Styled.SidebarLogo>
      <Styled.SidebarMenu>
        <SidebarMenuItems links={sidebarLinks} />
        {/*{*/}
        {/*  sidebarLinks.map(link => (*/}
        {/*    <NavLink*/}
        {/*      end*/}
        {/*      key={link.route}*/}
        {/*      style={{textDecoration: 'none'}}*/}
        {/*      to={link.route}*/}
        {/*    >*/}
        {/*      {({isActive}) => {*/}
        {/*        const Icon = link.icon;*/}

        {/*        return (*/}
        {/*          <Styled.SidebarMenuItem isActive={isActive}>*/}
        {/*            <Icon sx={(theme) => ({*/}
        {/*              ml: 2,*/}
        {/*              mr: 2.5,*/}
        {/*              color: isActive && theme.palette.lightOrange*/}
        {/*            })}/>*/}
        {/*            <span>{link.title}</span>*/}
        {/*          </Styled.SidebarMenuItem>*/}
        {/*        )*/}
        {/*      }}*/}

        {/*    </NavLink>*/}
        {/*  ))*/}
        {/*}*/}
      </Styled.SidebarMenu>
      <Styled.SidebarFooter>
        <Styled.FooterIcon>
          <PersonIcon/>
        </Styled.FooterIcon>
        <Styled.SidebarUserInfo>
          <Typography>{userData?.username}</Typography>
          <Typography>{userData?.usertype}</Typography>
        </Styled.SidebarUserInfo>
        <LogoutIcon
          onClick={() => dispatch(logoutUser())}
          sx={{cursor: 'pointer'}}
        />
      </Styled.SidebarFooter>
    </Styled.SidebarContainer>
  )
}