export const PERMISSIONS = {
  SUPER_ADMIN: 'super_admin',
  QUICKSTAT: {
    VIEW: 'quickstat.view',
  },
  CALLS: {
    BY_NUMBER: {
      RELATED: {
        VIEW: 'calls.by-number.related.view'
      },
      GLOBAL: {
        VIEW: 'calls.by-number.global.view'
      }
    }
  },
  PROVIDER: {
    VIEW: 'provider.view',
    EDIT: 'provider.edit',
    WALLET_EDIT: 'provider.wallet-edit', // TODO: [BE] check this permission due to provider's patch request, if no trigger an error 403 permission denied

    PAYMENT: {
      VIEW: 'provider.payment.view',
      CREATE: 'provider.payment.create',
    },
  },
  USER: {
    VIEW: 'user.view',
    EDIT: 'user.edit',
  },
  WALLET: {
    VIEW: 'wallet.view',
    EDIT: 'wallet.edit', // FIXME: No route?
  },
  TELEGRAM_BOT: {
    CHATS: {
      VIEW: 'telegram-bot.chats.view',
      EDIT: 'telegram-bot.chats.edit',
    },
    MESSAGE: {
      VIEW: 'telegram-bot.message.view',
      EDIT: 'telegram-bot.message.edit',
      SEND: 'telegram-bot.message.send',
    }
  }
}