import {useMutation, useQuery} from "@tanstack/react-query";
import {getProvider} from "../api-service/get-provider";
import {updateProvider} from "../api-service/update-provider";

export const useUpdateProvider = ({providerId, ...props}) => {
  return useMutation({
    // queryKey: ['provider', id],
    mutationFn: (provider) => updateProvider(providerId, provider),
    select: ({data}) => data,
    ...props,
  })
}