import { NavLink, useLocation } from 'react-router-dom';
import { List, ListItemIcon, ListItemText, Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import { usePermissionAccess } from '@hooks/use-permission-access/use-permission-access';
import {
  SidebarHeaderItem,
  SidebarHeaderItemWrapper,
  SidebarMenu,
  // SidebarMenuItem,
  SidebarMenuItemButton,
} from './sidebar-menu-items.styles';
import AccordionSummary from '@mui/material/AccordionSummary';
import {SidebarMenuItem} from "./sidebar-menu-items.styles";
// import { selectActiveBrand } from '@modules/brands/store/brand-selector';


export const SidebarMenuItems = ({ links }) => {
  const location = useLocation();
  // const { hasPermissions } = usePermissionAccess();

  // const activeBrand = useSelector(selectActiveBrand);

  // const isGlobalConfiguration = activeBrand?.name === 'system';

  return (
    <List>
      {links.map((menuItem) => {
        // if (!hasPermissions(menuItem.access)) {
        //     return null;
        // }
        //
        // if (menuItem.needActiveBrand && isGlobalConfiguration) {
        //     return null;
        // }
        //
        // if (
        //     menuItem.subData?.every((subItem) => !hasPermissions(subItem.access))
        // ) {
        //     return null;
        // }

        if (menuItem.subData) {
          const isContainsActiveLink = !!menuItem.subData.find(
            (route) => route.route === location.pathname
          );

          return (
            <SidebarMenuItem
              key={menuItem.label}
              disableRipple
            >
              {menuItem.isSectionHeading ? (
                <>
                  <SidebarHeaderItemWrapper>
                    <SidebarHeaderItem>{menuItem.icon} {menuItem.label}</SidebarHeaderItem>
                  </SidebarHeaderItemWrapper>

                  <SidebarMenuItems links={menuItem.subData} />
                </>
              ) : (
                <Accordion defaultExpanded={isContainsActiveLink}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    id="panel1a-header"
                  >
                    {menuItem.icon && (
                      <ListItemIcon>
                        {menuItem.icon}
                      </ListItemIcon>
                    )}
                    <Typography>{menuItem.label}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <SidebarMenuItems links={menuItem.subData} />
                  </AccordionDetails>
                </Accordion>
              )}
            </SidebarMenuItem>
          );
        }

        return (
          menuItem.route && (
            <SidebarMenuItem
              key={menuItem.label}
              disablePadding
              component={NavLink}
              to={menuItem.route}
              end
            >
              <SidebarMenuItemButton disableRipple>
                {menuItem.icon && <ListItemIcon>{menuItem.icon}</ListItemIcon>}
                <ListItemText primary={menuItem.label} />
              </SidebarMenuItemButton>
            </SidebarMenuItem>
          )
        );
      })}
    </List>
  );
};
