import {styled} from "@mui/material";

export const DashboardGreeting = styled('div', {name: 'DashboardGreeting'})(({theme}) => ({
  marginBottom: theme.spacing(3.125),

  '& p': {
    '&:nth-child(1)': {
      fontSize: theme.spacing(2.5),
      fontWeight: 600
    },
    '&:nth-child(2)': {
      fontSize: theme.spacing(2.25),
    },
  }
}))
