import {useSearchParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {Typography} from "@mui/material"
import CircularProgress from '@mui/material/CircularProgress';
import {CallsTable} from "../calls-table/calls-table"
import {useGetCallsSearch} from "../../api/api-hooks/use-get-calls-search";
import {selectSearchPhone} from "../../store/call-search.selectors";
import {prepareCalls} from "../../helpers/prepare-calls";

export const CallSearchResults = () => {
  const [searchParams] = useSearchParams()
  const previousSearchPhone = useSelector(selectSearchPhone)

  const searchPhone = searchParams.get('phone') || '';
  // const isLoading = false;
  const {isLoading, data: calls} = useGetCallsSearch(searchPhone || previousSearchPhone);

  return (
    <>
      {isLoading && <CircularProgress color="primary"/>}

      {calls && (
        <>
          <Typography sx={{mb: 2}}>Результаты поиска:</Typography>
          <CallsTable rows={prepareCalls(calls)}/>
        </>
      )}
    </>
  )
}

var calls = [
  {
    "calldate2": "2024-04-20 07:21:27",
    "timezone": "GMT +00:00",
    "clid": "18861",
    "destination": "Russian Federation Russia - Mobile MTS",
    "did": "",
    "did_inc_price": "0.0",
    "did_price": "0.0",
    "did_prov_price": "0.0",
    "dispod": "NO ANSWER(19)",
    "dst": "79181477403",
    "dst_user_id": "0",
    "lega_call_id": "",
    "legb_call_id": "",
    "nice_billsec": "0",
    "nice_reseller": " ",
    "originator_codec": "alaw",
    "prefix": "791",
    "privacy": "0",
    "profit": "0.0000",
    "provider_name": "TibetTibet",
    "provider_price": "0.0",
    "provider_rate": "0.23",
    "reseller_price": "0.0",
    "reseller_rate": "0.0",
    "server_id": "2",
    "src": "18861",
    "terminated_by": "terminator",
    "terminator_codec": "",
    "user": "RARRARRAR",
    "user_currency": "USD",
    "user_exchange_rate": "1.000000000000000",
    "user_id": "201",
    "user_price": "0.0",
    "user_rate": "0.32",
    "uniqueid": "1713597687.2832163",
    "originator_codec_name": "G.711 A-law",
    "terminator_codec_name": ""
  },
  {
    "calldate2": "2024-04-20 07:21:27",
    "timezone": "GMT +00:00",
    "clid": "18861",
    "destination": "Russian Federation Russia - Mobile MTS",
    "did": "",
    "did_inc_price": "0.0",
    "did_price": "0.0",
    "did_prov_price": "0.0",
    "dispod": "FAILED(34)",
    "dst": "79181477403",
    "dst_user_id": "0",
    "lega_call_id": "",
    "legb_call_id": "",
    "nice_billsec": "0",
    "nice_reseller": " ",
    "originator_codec": "alaw",
    "prefix": "791",
    "privacy": "0",
    "profit": "0.0000",
    "provider_name": "Cult",
    "provider_price": "0.0",
    "provider_rate": "0.23",
    "reseller_price": "0.0",
    "reseller_rate": "0.0",
    "server_id": "2",
    "src": "18861",
    "terminated_by": "terminator",
    "terminator_codec": "",
    "user": "RARRARRAR",
    "user_currency": "USD",
    "user_exchange_rate": "1.000000000000000",
    "user_id": "201",
    "user_price": "0.0",
    "user_rate": "0.32",
    "uniqueid": "1713597687.2832163",
    "originator_codec_name": "G.711 A-law",
    "terminator_codec_name": ""
  },
  {
    "calldate2": "2024-04-20 07:21:27",
    "timezone": "GMT +00:00",
    "clid": "18861",
    "destination": "Russian Federation Russia - Mobile MTS",
    "did": "",
    "did_inc_price": "0.0",
    "did_price": "0.0",
    "did_prov_price": "0.0",
    "dispod": "ANSWERED(16)",
    "dst": "79181477403",
    "dst_user_id": "0",
    "lega_call_id": "",
    "legb_call_id": "",
    "nice_billsec": "1427",
    "nice_reseller": " ",
    "originator_codec": "alaw",
    "prefix": "791",
    "privacy": "0",
    "profit": "0.4757",
    "provider_name": "Prizma",
    "provider_price": "7.135",
    "provider_rate": "0.3",
    "reseller_price": "0.0",
    "reseller_rate": "0.0",
    "server_id": "2",
    "src": "18861",
    "terminated_by": "originator",
    "terminator_codec": "alaw",
    "user": "RARRARRAR",
    "user_currency": "USD",
    "user_exchange_rate": "1.000000000000000",
    "user_id": "201",
    "user_price": "7.6107",
    "user_rate": "0.32",
    "uniqueid": "1713597687.2832163",
    "originator_codec_name": "G.711 A-law",
    "terminator_codec_name": "G.711 A-law"
  },
  {
    "calldate2": "2024-04-20 07:20:36",
    "timezone": "GMT +00:00",
    "clid": "18861",
    "destination": "Russian Federation Russia - Mobile MTS",
    "did": "",
    "did_inc_price": "0.0",
    "did_price": "0.0",
    "did_prov_price": "0.0",
    "dispod": "FAILED(38)",
    "dst": "79181477403",
    "dst_user_id": "0",
    "lega_call_id": "",
    "legb_call_id": "",
    "nice_billsec": "0",
    "nice_reseller": " ",
    "originator_codec": "alaw",
    "prefix": "791",
    "privacy": "0",
    "profit": "0.0000",
    "provider_name": "Tibet",
    "provider_price": "0.0",
    "provider_rate": "0.23",
    "reseller_price": "0.0",
    "reseller_rate": "0.0",
    "server_id": "2",
    "src": "18861",
    "terminated_by": "terminator",
    "terminator_codec": "",
    "user": "RARRARRAR",
    "user_currency": "USD",
    "user_exchange_rate": "1.000000000000000",
    "user_id": "201",
    "user_price": "0.0",
    "user_rate": "0.32",
    "uniqueid": "1713597636.2831433",
    "originator_codec_name": "G.711 A-law",
    "terminator_codec_name": ""
  },
  {
    "calldate2": "2024-04-20 07:20:36",
    "timezone": "GMT +00:00",
    "clid": "18861",
    "destination": "Russian Federation Russia - Mobile MTS",
    "did": "",
    "did_inc_price": "0.0",
    "did_price": "0.0",
    "did_prov_price": "0.0",
    "dispod": "FAILED(223)",
    "dst": "79181477403",
    "dst_user_id": "0",
    "lega_call_id": "",
    "legb_call_id": "",
    "nice_billsec": "0",
    "nice_reseller": " ",
    "originator_codec": "alaw",
    "prefix": "791",
    "privacy": "0",
    "profit": "0.0000",
    "provider_name": "Cult",
    "provider_price": "0.0",
    "provider_rate": "0.23",
    "reseller_price": "0.0",
    "reseller_rate": "0.0",
    "server_id": "2",
    "src": "18861",
    "terminated_by": "system",
    "terminator_codec": "",
    "user": "RARRARRAR",
    "user_currency": "USD",
    "user_exchange_rate": "1.000000000000000",
    "user_id": "201",
    "user_price": "0.0",
    "user_rate": "0.32",
    "uniqueid": "1713597636.2831433",
    "originator_codec_name": "G.711 A-law",
    "terminator_codec_name": ""
  },
  {
    "calldate2": "2024-04-20 07:20:36",
    "timezone": "GMT +00:00",
    "clid": "18861",
    "destination": "Russian Federation Russia - Mobile MTS",
    "did": "",
    "did_inc_price": "0.0",
    "did_price": "0.0",
    "did_prov_price": "0.0",
    "dispod": "ANSWERED(16)",
    "dst": "79181477403",
    "dst_user_id": "0",
    "lega_call_id": "",
    "legb_call_id": "",
    "nice_billsec": "5",
    "nice_reseller": " ",
    "originator_codec": "alaw",
    "prefix": "791",
    "privacy": "0",
    "profit": "0.0017",
    "provider_name": "Prizma",
    "provider_price": "0.025",
    "provider_rate": "0.3",
    "reseller_price": "0.0",
    "reseller_rate": "0.0",
    "server_id": "2",
    "src": "18861",
    "terminated_by": "originator",
    "terminator_codec": "alaw",
    "user": "RARRARRAR",
    "user_currency": "USD",
    "user_exchange_rate": "1.000000000000000",
    "user_id": "201",
    "user_price": "0.0267",
    "user_rate": "0.32",
    "uniqueid": "1713597636.2831433",
    "originator_codec_name": "G.711 A-law",
    "terminator_codec_name": "G.711 A-law"
  },
  {
    "calldate2": "2024-04-20 07:19:30",
    "timezone": "GMT +00:00",
    "clid": "18861",
    "destination": "Russian Federation Russia - Mobile MTS",
    "did": "",
    "did_inc_price": "0.0",
    "did_price": "0.0",
    "did_prov_price": "0.0",
    "dispod": "FAILED(38)",
    "dst": "79181477403",
    "dst_user_id": "0",
    "lega_call_id": "",
    "legb_call_id": "",
    "nice_billsec": "0",
    "nice_reseller": " ",
    "originator_codec": "alaw",
    "prefix": "791",
    "privacy": "0",
    "profit": "0.0000",
    "provider_name": "Tibet",
    "provider_price": "0.0",
    "provider_rate": "0.23",
    "reseller_price": "0.0",
    "reseller_rate": "0.0",
    "server_id": "2",
    "src": "18861",
    "terminated_by": "terminator",
    "terminator_codec": "",
    "user": "RARRARRAR",
    "user_currency": "USD",
    "user_exchange_rate": "1.000000000000000",
    "user_id": "201",
    "user_price": "0.0",
    "user_rate": "0.32",
    "uniqueid": "1713597570.2830101",
    "originator_codec_name": "G.711 A-law",
    "terminator_codec_name": ""
  },
  {
    "calldate2": "2024-04-20 07:19:30",
    "timezone": "GMT +00:00",
    "clid": "18861",
    "destination": "Russian Federation Russia - Mobile MTS",
    "did": "",
    "did_inc_price": "0.0",
    "did_price": "0.0",
    "did_prov_price": "0.0",
    "dispod": "FAILED(34)",
    "dst": "79181477403",
    "dst_user_id": "0",
    "lega_call_id": "",
    "legb_call_id": "",
    "nice_billsec": "0",
    "nice_reseller": " ",
    "originator_codec": "alaw",
    "prefix": "791",
    "privacy": "0",
    "profit": "0.0000",
    "provider_name": "Cult",
    "provider_price": "0.0",
    "provider_rate": "0.23",
    "reseller_price": "0.0",
    "reseller_rate": "0.0",
    "server_id": "2",
    "src": "18861",
    "terminated_by": "terminator",
    "terminator_codec": "",
    "user": "RARRARRAR",
    "user_currency": "USD",
    "user_exchange_rate": "1.000000000000000",
    "user_id": "201",
    "user_price": "0.0",
    "user_rate": "0.32",
    "uniqueid": "1713597570.2830101",
    "originator_codec_name": "G.711 A-law",
    "terminator_codec_name": ""
  },
  {
    "calldate2": "2024-04-20 07:19:30",
    "timezone": "GMT +00:00",
    "clid": "18861",
    "destination": "Russian Federation Russia - Mobile MTS",
    "did": "",
    "did_inc_price": "0.0",
    "did_price": "0.0",
    "did_prov_price": "0.0",
    "dispod": "FAILED(21)",
    "dst": "79181477403",
    "dst_user_id": "0",
    "lega_call_id": "",
    "legb_call_id": "",
    "nice_billsec": "0",
    "nice_reseller": " ",
    "originator_codec": "alaw",
    "prefix": "791",
    "privacy": "0",
    "profit": "0.0000",
    "provider_name": "XDarkPnone",
    "provider_price": "0.0",
    "provider_rate": "0.28",
    "reseller_price": "0.0",
    "reseller_rate": "0.0",
    "server_id": "2",
    "src": "18861",
    "terminated_by": "terminator",
    "terminator_codec": "",
    "user": "RARRARRAR",
    "user_currency": "USD",
    "user_exchange_rate": "1.000000000000000",
    "user_id": "201",
    "user_price": "0.0",
    "user_rate": "0.32",
    "uniqueid": "1713597570.2830101",
    "originator_codec_name": "G.711 A-law",
    "terminator_codec_name": ""
  },
  {
    "calldate2": "2024-04-20 07:19:30",
    "timezone": "GMT +00:00",
    "clid": "18861",
    "destination": "Russian Federation Russia - Mobile MTS",
    "did": "",
    "did_inc_price": "0.0",
    "did_price": "0.0",
    "did_prov_price": "0.0",
    "dispod": "ANSWERED(16)",
    "dst": "79181477403",
    "dst_user_id": "0",
    "lega_call_id": "",
    "legb_call_id": "",
    "nice_billsec": "6",
    "nice_reseller": " ",
    "originator_codec": "alaw",
    "prefix": "791",
    "privacy": "0",
    "profit": "0.0020",
    "provider_name": "Prizma",
    "provider_price": "0.03",
    "provider_rate": "0.3",
    "reseller_price": "0.0",
    "reseller_rate": "0.0",
    "server_id": "2",
    "src": "18861",
    "terminated_by": "originator",
    "terminator_codec": "alaw",
    "user": "RARRARRAR",
    "user_currency": "USD",
    "user_exchange_rate": "1.000000000000000",
    "user_id": "201",
    "user_price": "0.032",
    "user_rate": "0.32",
    "uniqueid": "1713597570.2830101",
    "originator_codec_name": "G.711 A-law",
    "terminator_codec_name": "G.711 A-law"
  }
]