import axios from "axios";

import {RocketApi} from "../../api/rocket-api";
import {store} from '../../store/store'
import {logout} from "../../store/auth/auth.slice";
import {isDev} from "../helpers/environment";

import frontendConfig from '../../../frontend.config';

const baseUrl = isDev
  ? `${frontendConfig.localDomain}/api/`
  : `${frontendConfig.host}/api`;

export class BaseHttpService {
  constructor() {
    this.axiosInstance = axios.create({
      withCredentials: true,
      baseURL: baseUrl,
      headers: {'Content-Type': 'application/json'},
      retry: 2
    });

    this.axiosInstance.interceptors.request.use(
      async config => {
        const accessToken = localStorage.getItem('accessToken');

        config.headers = {
          'Authorization': `Bearer ${accessToken}`,
          'Accept': 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded'
        }

        return config;
      }
    );

    this.axiosInstance.interceptors.response.use(
      response => response,
      async (error) => {
        const originalRequest = error.config;

        if (error?.response?.status === 401 && !originalRequest._retry && originalRequest.retry > 0) {
          originalRequest._retry = false;
          error.config.__isRetryRequest = false;
          originalRequest.retry -= 1;

          try {
            const accessToken = await RocketApi.refreshToken();

            axios.defaults.headers.common['Authorization'] = 'Bearer ' + accessToken;

            return this.axiosInstance(originalRequest);
          } catch (error) {
            store.dispatch(logout())
          }
        }

        if (error?.response?.status === 401 && !originalRequest._retry && originalRequest.retry === 0) {
          store.dispatch(logout())
        }

        return Promise.reject(error);
      }
    )
  }
}
