import {createBrowserRouter} from "react-router-dom";

import App from "../../App";
import {
  Dashboard,
  LoginPage,
  UsersPage,
  CallSearchPage,
  ProvidersListPage,
  ProviderEditPage
} from "../../pages";
import {ROUTES} from "./routes";
import {ProtectedRoute} from "./protected-route";
import {
  ProviderPaymentListPage,
  providerPaymentListPage
} from "../../pages/providers-payment/list/provider-payment-list-page";
import {PermittedRoute} from "./permitted-route";
import {PERMISSIONS} from "../../../../app/permissions/permissions";
// import {ProviderEditPage } from "@pages";

const protectedRoutes = [
  {
    element: <ProtectedRoute />,
    children: [
      {
        path: ROUTES.DASHBOARD,
        element: <App />,
        children: [
          {
            path: ROUTES.DASHBOARD,
            element: <Dashboard />,
          },
          {
            path: ROUTES.CALL_SEARCH,
            element: <CallSearchPage />,
          },
          {
            path: ROUTES.PROVIDERS,
            element: (
              <PermittedRoute permissions={PERMISSIONS.PROVIDER.VIEW}>
                <ProvidersListPage />
              </PermittedRoute>
            ),
          },
          {
            path: ROUTES.PROVIDER,
            element: (
              <PermittedRoute permissions={PERMISSIONS.PROVIDER.VIEW}>
                <ProviderEditPage />
              </PermittedRoute>
            ),
          },
          {
            path: ROUTES.USERS,
            element: <UsersPage />,
          },
          {
            path: ROUTES.PROVIDERS_PAYMENTS,
            element: <ProviderPaymentListPage />,
          }
        ]
      },
    ]
  }
]

export const router = createBrowserRouter([
  ...protectedRoutes,
  {
    path: "/login",
    element: <LoginPage/>,
  },
]);
