import {AmountVariant, AmountVariantsContainer} from "./refill-provider-form.styled";
import {useFormikContext} from "formik";
import {REFILL_PROVIDER_FORM_FIELDS} from "./refill-provider-form";

export const RefillProviderFormVariants = () => {
  const { setFieldValue } = useFormikContext();

  const updateAmountValue = (amount) => {
    setFieldValue(REFILL_PROVIDER_FORM_FIELDS.AMOUNT, amount)
  }

  return (
    <AmountVariantsContainer>
      <AmountVariant onClick={() => updateAmountValue(300)}>300</AmountVariant>
      <AmountVariant onClick={() => updateAmountValue(500)}>500</AmountVariant>
      <AmountVariant onClick={() => updateAmountValue(700)}>700</AmountVariant>
    </AmountVariantsContainer>
  )
}