import {TextField} from "formik-mui";
import {Field} from "formik";
import {Box, FormControlLabel, InputAdornment} from "@mui/material";
import {AccountCircle} from "@mui/icons-material";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

export const BasicTextField = ({label, icon, iconPosition = 'start', ...props}) => {
  const textFieldProps = {
    ...(icon && ({
      InputProps: {
        [`${iconPosition}Adornment`]: (
          <InputAdornment position={iconPosition}>
            {icon}
          </InputAdornment>
        ),
      }
    }))
  }

  if (label) {
    return (
      <FormControlLabel
        control={(
          <Field
            component={TextField}
            fullWidth
            sx={{
              backgroundColor: '#fff',
            }}
            {...textFieldProps}
          />
        )}
        label={(
          <Box sx={{display: 'flex'}}>
            {label}
          </Box>
        )}
        sx={(theme) => ({
          alignItems: "flex-start",
          margin: 0,
          width: '100%',

          '& .MuiFormControlLabel-label': {
            marginBottom: theme.spacing(.5),
          }
        })}
        labelPlacement="top"
        {...props}
      />
    )
  }

  return (
    <Field
      component={TextField}
      fullWidth
      {...props}
    />
  )
}