import {Typography, Grid, Box} from "@mui/material";
import Paper from '@mui/material/Paper';
import Chart from "react-apexcharts";
import PropTypes from 'prop-types';
import { QuickStat } from "../quick-stat/quick-stat";

export const DashboardStat = ({stats}) => {
  const chartData = {
    series: [Number(stats.active_calls.total), Number(stats.active_calls.answered_calls)],
    options: {
      chart: {
        type: 'donut',
        offsetX: -55
      },
      labels: [`Всего звонков`, `Отвеченные`],
      legend: {
        fontSize: '20px',
        position: 'top',
        show: 'true',
        horizontalAlign: 'center',
        onItemHover: {
          highlightDataSeries: true
        },
      },
      plotOptions: {
        pie: {
          donut: {
            size: "75%",
            labels: {
              show: true,
              total: {
                show: true,
                showAlways: false,
                label: 'Всего звонков',
                fontSize: '22px',
                fontFamily: 'Helvetica, Arial, sans-serif',
                fontWeight: 600,
                color: '#373d3f',
                formatter: function () {
                  return stats?.active_calls.total
                }
              }
            }
          }
        }
      },
      dataLabels: {
        formatter: function (val, opts) {
            return opts.w.config.series[opts.seriesIndex]
        },
      },
    },
  };

  return (
    <Paper elevation={0} sx={{padding: 2}}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Grid container spacing={2}>
            {stats?.today && (
              <Grid item xs={6}>
                <Typography sx={{fontWeight: 900, mb: 4}} variant="h6" >Сегодня:</Typography>
                <QuickStat stat={stats.today} />
              </Grid>
            )}
            {stats?.this_month && (
              <Grid item xs={6}>
                <Typography sx={{mb: 4}} variant="h6">В этом месяце:</Typography>
                {stats?.this_month && <QuickStat stat={stats.this_month} />}
              </Grid>
            )}
          </Grid>
        </Grid>
        {stats?.active_calls && (
          <Grid item xs={6}>
            <Typography sx={{mb: 2}} variant="h6">
              Активные звонки
            </Typography>
            <Box>
              <Chart
                options={chartData.options}
                series={chartData.series}
                type="donut"
                width="400"
              />
            </Box>
          </Grid>
        )}
      </Grid>
    </Paper>
  )
}

DashboardStat.prototypes = {
  active_calls: PropTypes.shape({
    answered_calls: PropTypes.string.isRequired,
    total: PropTypes.string.isRequired,
  }),
  this_month: PropTypes.shape({
    calls: PropTypes.string.isRequired,
    duration: PropTypes.string.isRequired,
  }),
  today: PropTypes.shape({
    calls: PropTypes.string.isRequired,
    duration: PropTypes.string.isRequired,
  }),
}
