import {useNavigate} from 'react-router-dom';
import {useEffect} from 'react';
import {Box} from "@mui/material";
import callcentergif from '@assets/images/callcenter.gif';

import {ShowNotification} from '../../components/show-notification/show-notification';
import {useAuth} from '../../hooks/use-auth';
import {LoginSidebar} from "../../modules/login/components/login-sidebar/login-sidebar";
import {ROUTES} from "../../system/router/routes";

import * as Styled from './login.styled';

export const LoginPage = () => {
  const {isUserLogged} = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (isUserLogged) {
      navigate(ROUTES.DASHBOARD);
    }
  }, [isUserLogged, navigate]);

  return (
    <Box sx={{display: 'flex'}}>
      <LoginSidebar/>
      <Styled.LoginPageGraphic>
        <img alt="" src={callcentergif}/>
      </Styled.LoginPageGraphic>
      <ShowNotification/>
    </Box>
  )
}