import {splitStatusStringToObject} from "../../../helpers/splitStatusStringToObject";

export const prepareCalls = (calls) => {
  const callsIds = calls.map(call => call.uniqueid);
  const uniqueIds = Array.from(new Set(callsIds));

  const result = uniqueIds.map(callId => {
    const oneFlowCalls = calls.filter(call => call.uniqueid === callId);

    if (oneFlowCalls.length === 1) {
      return oneFlowCalls[0];
    }

    const answeredCall = oneFlowCalls.find(call => {
      const { status } = splitStatusStringToObject(call.dispod);

      return status === 'answered';
    })

    const nonAnsweredCall = oneFlowCalls.find(call => {
      const { status } = splitStatusStringToObject(call.dispod);

      return status === 'no-answer';
    })

    const headCall = answeredCall || nonAnsweredCall;

    headCall.merged = [];

    oneFlowCalls.forEach(call => {
      if (call !== headCall) {
        headCall.merged.push(call);
      }
    });

    return headCall;
  })

  return result;
}