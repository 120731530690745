import {Outlet} from 'react-router-dom';
import {Sidebar} from './components/layout/sidebar/sidebar';
import {ShowNotification} from './components/show-notification/show-notification';
import {ModalProvider} from "./providers/modal-provider";
import {CallPlayer} from "./modules/audio-player/components/audio-player";
import {NotificationsProvider} from "./providers/notification-provider";

import * as Styled from './App.styled';

const App = () => {
  return (
    <ModalProvider>
      <NotificationsProvider>
        <Styled.Page>
          <Sidebar/>
          <Styled.Main>
            <Outlet/>
          </Styled.Main>
          <CallPlayer/>
        </Styled.Page>
      </NotificationsProvider>
    </ModalProvider>
  );
}

export default App;
