import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Box, Typography} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import ErrorIcon from '@mui/icons-material/Error';
import {selectNotifications} from "../../store/notifications/notifications.selectors";
import {hideNotification} from "../../store/notifications/notifications.slice";

import * as Styled from './show-notification.styled'

export const ShowNotification = () => {
  const dispatch = useDispatch();
  const notifications = useSelector(selectNotifications);

  const notificationIconMap = {
    'success': CheckCircleIcon,
    'error': CancelIcon,
    'info': ErrorIcon
  }

  useEffect(() => {
    if (notifications.length > 0) {
      const lastNotification = notifications.at(-1);
      setTimeout(() => {
        dispatch(hideNotification(lastNotification.id));
      }, lastNotification.time * 1000)
    }
  }, [dispatch, notifications])

  const handleClose = (id) => {
    dispatch(hideNotification(id));
  }

  return (
    <>
      {notifications.map((notification, index) => {
        const NotificationIcon = notificationIconMap[notification.type];

        return (
          <Styled.NotificationItem
            key={index}
            notificationtype={notification.type}
            sx={{bottom: `${(20 + 30) * (index) + 20}px`}}
          >
            <NotificationIcon/>
            <Box sx={{marginLeft: '10px'}}>
              <Typography component="p" sx={{fontSize: (theme) => theme.spacing(1.75)}}>{notification.description}</Typography>
            </Box>
            <Styled.NotificationClose className="fa-solid fa-xmark" onClick={() => handleClose(notification.id)} />
          </Styled.NotificationItem>
        )
      })}
    </>
  )
}