import {styled} from "@mui/material";

export const LoginSidebarContainer = styled('div', {name: 'LoginSidebarContainer'})(({theme}) => ({
  height: '100vh',
  width: theme.spacing(43.75),
  padding: theme.spacing(5),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  background: 'linear-gradient(41deg, rgb(255 131 54 / 18%) 0%, rgb(255 255 255 / 0%) 100%)'
}));

export const LoginLogo = styled('img', {name: 'LoginLogo'})(({theme}) => ({
  width: theme.spacing(8.75),
  marginBottom: theme.spacing(2.5),
}));