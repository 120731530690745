export const ANSWER_TYPES = {
  NO_ANSWER: 'no answer',
  ANSWERED: 'answered',
  FAILED: 'failed',
}

export const ANSWER_TYPES_COLORS = {
  [ANSWER_TYPES.NO_ANSWER]: 'no answer',
  [ANSWER_TYPES.ANSWERED]: 'answered',
  [ANSWER_TYPES.FAILED]: 'failed',
}

export const splitStatusStringToObject = (statusString = '') => {
  const [rawStatus, rawStatusCode] = statusString.toLocaleLowerCase().split('(');
  const status = rawStatus.replace(' ', '-');
  const statusCode = rawStatusCode.slice(0, rawStatusCode.length - 1);

  return {
    status,
    statusCode
  }
}