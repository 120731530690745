import {useSelector} from 'react-redux';
import {DashboardStatWrapper} from "../../modules/dashboard/components/dashboard-stat/dashboard-stat.styled";
import {DashboardHeader} from "../../modules/dashboard/components/dashboard-header/dashboard-header";
import {DashboardStat} from "../../modules/dashboard/components/dashboard-stat/dashboard-stat";
import {useGetQuickstat} from "../../modules/dashboard/api/api-hooks/use-get-quickstat";
import {Loader} from '../../components/loader/loader'
import {userDataSelector} from "../../store/user/user.selectors"


export const Dashboard = () => {
  const {data: stats, isLoading} = useGetQuickstat();
  const userData = useSelector(userDataSelector);
  const userType = userData?.usertype

  return(
      <DashboardStatWrapper>
          <DashboardHeader userData={userData}/>
          {isLoading ? (
            <Loader />
          ) : (
            (!!stats && userType === 'admin') ? (
              <DashboardStat stats={stats} />
            ) : (!!stats && userType === 'user') ? null :  (
              <>Error. No data available</>
            )
          )}
      </DashboardStatWrapper>
  )
}