import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import CheckIcon from '@mui/icons-material/Check';
import DoDisturbAltIcon from '@mui/icons-material/DoDisturbAlt';
import {Typography, Box, Tooltip} from '@mui/material';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import DownloadIcon from '@mui/icons-material/Download';
import SmsFailedIcon from '@mui/icons-material/SmsFailed';
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';
import {useDispatch} from "react-redux";

import {playCall} from "../../../../store/player/player.slice";
import {useGetRecording} from "../../../audio-player/api/api-hooks/use-get-recording";
import {splitStatusStringToObject} from "../../../../helpers/splitStatusStringToObject";
import {HANGUP_CODES} from "../../../../system/constants/hangup-codes";

dayjs.extend(duration);
dayjs.extend(utc)
dayjs.extend(timezone)

const PlayCallComponent = ({row}) => {
  const dispatch = useDispatch();
  const {refetch} = useGetRecording(row, {enabled: false});

  const handleDownload = () => {
    refetch().then(({data}) => {
      const link = document.createElement('a')
      const objectUrl = URL.createObjectURL(new Blob([data], {type: "application/octet-stream"}));
      const preparedCallTime = (new Date(`${row.calldate2}.000Z`)).toTimeString().split(' ').at(0).replaceAll(':', '-');
      const preparedCallDate = `date_${row.calldate2.split(' ').at(0)}_time_${preparedCallTime}`

      link.setAttribute('href', objectUrl)
      link.setAttribute('download', `${row.dst}_${preparedCallDate}.mp3`)
      link.style.display = 'none'

      document.body.appendChild(link)

      link.click()

      document.body.removeChild(link)
    })
  }

  return (
    <>
      <PlayCircleIcon onClick={() => dispatch(playCall(row))} sx={{cursor: 'pointer'}}/>
      <DownloadIcon onClick={handleDownload} sx={{cursor: 'pointer'}}/>
    </>
  )
}


export const callsColumns = [
  {
    field: 'id',
    flex: 1,
    sortable: false,
  },
  {
    field: 'calldate2',
    headerName: 'Дата',
    flex: 1.5,
    sortable: false,
    headerAlign: 'center',
    align: 'center',
    minWidth: 145,
    renderCell: ({row}) => {
      const callDate = dayjs.utc(row.calldate2).tz('Europe/Kiev');
      const now = dayjs();

      const diff = now.diff(callDate);

      let seconds = diff / 1000;

      const days = parseInt(seconds / (24 * 3600));
      seconds = seconds % (24 * 3600);

      const hours = parseInt(seconds / 3600);
      seconds = seconds % 3600;

      const minutes = parseInt(seconds / 60);
      seconds = parseInt(seconds % 60);

      const prepareCallTime = () => (`
        ${days > 0 ? (`${days} д.`) : ''}
        ${hours > 0 ? (`${hours} ч.`) : ''}
        ${minutes > 0 ? (`${minutes} м.`) : ''}
        ${(minutes === 0) && (seconds > 0) && (`${seconds} cек.`) || ''}
      `)

      return (
        <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
          <Typography sx={{fontSize: '12px'}} variant="body1">{callDate.format('DD.MM.YYYY HH:mm:ss')}</Typography>
          <Typography sx={{fontSize: '9px'}} variant="caption">
            {prepareCallTime()} назад.
          </Typography>
        </Box>
      )
    }
  },
  {
    field: 'clid',
    headerName: 'От кого',
    flex: .7,
    sortable: false,
    headerAlign: 'center',
    align: 'center',
    renderCell: ({row: {clid = ''}}) => {
      if (clid.includes('<')) {
        const [sip] = clid.replaceAll('"', '').split(' ');

        return sip;
      }

      return clid;
    }
  },
  {
    field: 'dst',
    headerName: 'Номер',
    flex: 1.2,
    sortable: false,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'nice_billsec',
    headerName: 'Длительность',
    flex: 1.2,
    sortable: false,
    headerAlign: 'center',
    align: 'center',
    renderCell: ({row: {nice_billsec}}) => dayjs.duration(+nice_billsec * 1000).format('HH:mm:ss')
  },
  {
    field: 'dispod',
    headerName: 'Статус',
    flex: 1,
    sortable: false,
    headerAlign: 'center',
    align: 'center',
    renderCell: ({row: { dispod }}) => {
      const { statusCode, status } = splitStatusStringToObject(dispod);

      return (
        <Tooltip title={HANGUP_CODES[statusCode]?.ru}>
          <Typography sx={{fontSize: '12px', '&:first-letter': {textTransform:'uppercase'}}}>{status}</Typography>
        </Tooltip>
      )
    }
  },
  {
    field: 'user',
    headerName: 'Юзер',
    flex: 1,
    sortable: false,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'provider_name',
    headerName: 'Провайдер',
    flex: 1,
    sortable: false,
    headerAlign: 'center',
    align: 'center',
    renderCell: ({row: call}) => {
      if (call.merged) {
        const failedProviders = call.merged.map(call => {
          return (
            <Box
              key={call.provider_name}
              sx={{
                display: 'flex',
                alignItems: 'center',
                borderBottom: '2px solid orange',

                '&:last-child': {
                  border: 0
                }
              }}
            >
              <CloseIcon sx={{ color: 'red', fontSize: '14px' }}/>
              <Typography sx={{fontSize: '12px'}}>{call.provider_name}</Typography>
            </Box>
          )
        })

        return (
          <Tooltip title={failedProviders}>
            <Box sx={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
              <InfoIcon sx={{color: 'primary.dark', fontSize: '12px', mr: .5}}/>
              <Typography sx={{fontSize: '12px'}}>{call.provider_name}</Typography>
            </Box>
          </Tooltip>
        )
      }

      return (
        <Box>
          <Typography sx={{fontSize: '12px'}}>{call.provider_name}</Typography>
        </Box>
      );
    }
  },
  {
    field: 'terminator_codec',
    headerName: 'Кодек',
    flex: 0.8,
    sortable: false,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'codecMath',
    headerName: 'CM',
    flex: 1,
    sortable: false,
    headerAlign: 'center',
    align: 'center',
    renderCell: ({row}) => {
      if (row.nice_billsec === '0') return '-';

      return (row.terminator_codec_name === row.originator_codec_name)
        ? <CheckIcon sx={{color: 'green'}}/>
        : <DoDisturbAltIcon sx={{color: 'red'}}/>
    }
  },
  {
    field: 'destination',
    headerName: 'Роут',
    flex: 1,
    sortable: false,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'terminated_by',
    headerName: 'Сброс',
    flex: 1,
    sortable: false,
    headerAlign: 'center',
    align: 'center',
    renderCell: ({row}) => {
      const initiator = row.terminated_by;

      if (initiator === 'originator') return 'Manager';
      if (initiator === 'terminator') return 'Client';

      return initiator;
    }
  },
  {
    field: 'recording',
    headerName: 'Запись',
    flex: 1,
    sortable: false,
    headerAlign: 'center',
    align: 'center',
    minWidth: 80,
    renderCell: ({row}) => {
      if (row.nice_billsec === '0') return '-';

      return <PlayCallComponent row={row}/>
    }
  },
]
