import {CircularProgress, Box} from '@mui/material';

export const Loader = ({color, ...props}) => {
  return(
    <Box sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      // textAlign: 'center',

      '& .MuiCircularProgress-root': {
        color,
      }
      // color: (theme) => theme.palette.primary.main,
    }}>
      <CircularProgress {...props}/>
    </Box>
  )
}
